const initialState = {
    events: [],
    media: [],
}

const GET_MEDIA = 'GET_MEDIA';
const GET_EVENTS = 'GET_EVENTS'

export const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MEDIA:
            return {...state,
                media: action.payload,
            }
        case GET_EVENTS:
            return {...state,
                events: action.payload,
            }
        default:
            return state;
    }
}

export const getMediaAction = (payload) => ({type: GET_MEDIA, payload})
export const getEventsAction = (payload) => ({type: GET_EVENTS, payload})