import React, { Component, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import styles from './SliderNews.module.scss';
import image from '../../../images/logo-blue.svg';
import { useState } from 'react';
// import classnames from "classnames";
import { isMobile } from 'react-device-detect';

export default class SliderNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const windowWidth = window.innerWidth;

    return (
      <div className={styles.slider} id='newsSlider'>
        <Slider
          // asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          arrows={isMobile ? false : true}
          adaptiveHeight={true}
        >
          {this.props.item &&
            this.props.item.map((item, index) => (
              <div
                key={index}
                onClick={() => this.props.openFullScreenImg(item)}
              >
                <div
                  style={
                    !isMobile
                      ? {
                          width: 303,
                          height: 303,
                          backgroundImage: 'url(' + item.cropped_image + ')',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          marginBottom: 30,
                        }
                      : windowWidth > 768 && windowWidth < 1001
                      ? {
                          width: 700,
                          height: 400,
                          backgroundImage: 'url(' + item.image + ')',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          marginBottom: 20,
                        }
                      : {
                          width: '100%',
                          height: 180,
                          backgroundImage: 'url(' + item.cropped_image + ')',
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          marginBottom: 20,
                        }
                  }
                ></div>
                {/* <img src={item.image} alt="" style={{height: 400}} /> */}
              </div>
            ))}
        </Slider>
        <img
          src={image}
          className={styles.decorImgae}
          style={
            isMobile
              ? windowWidth > 768
                ? { top: 341 + 'px' }
                : { top: 121 + 'px' }
              : { top: 188 + 'px' }
          }
        />
        {this.props.item.length > 1 && (
          <Slider
            // asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            slidesToShow={
              windowWidth > 768 && windowWidth < 1001
                ? this.props.item.length >= 6
                  ? 6
                  : this.props.item.length
                : this.props.item.length >= 3
                ? 3
                : this.props.item.length
            }
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={false}
            dots={isMobile ? true : false}
            beforeChange={(prev, next) => {
              this.setState({ currentSlide: next });
            }}
            className={
              this.props.item.length >= 3 ? 'center' : 'slider variable-width'
            }
            variableWidth={this.props.item.length >= 3 ? false : true}
            centerMode={this.props.item.length >= 3 ? true : false}
            centerPadding={
              this.props.item.length >= 3 ? (!isMobile ? -5 : -17) : 10
            }
            appendDots={(dots) => (
              <div style={{ bottom: '-50px' }}>
                <ul style={{ margin: '0px' }}> {dots} </ul>
              </div>
            )}
            customPaging={(i) => (
              <div
                style={
                  i === this.state.currentSlide
                    ? {
                        width: 11,
                        height: 11,
                        border: '1px solid #00405B',
                        borderRadius: '50%',
                        backgroundColor: '#00405B',
                      }
                    : {
                        width: 11,
                        height: 11,
                        border: '1px solid #FF2E17',
                        borderRadius: '50%',
                      }
                }
              >
                {/* {i + 1} */}
              </div>
            )}
          >
            {this.props.item &&
              this.props.item.map((point, index) => (
                <div key={index} style={{ width: 100 }}>
                  <div
                    style={
                      !isMobile
                        ? {
                            width: 90,
                            height: 90,
                            backgroundImage: 'url(' + point.cropped_image + ')',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                          }
                        : {
                            width: 77,
                            height: 77,
                            backgroundImage: 'url(' + point.cropped_image + ')',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            // marginBottom: 20
                          }
                    }
                  ></div>
                  {/* <img src={item.image} alt="" style={{width: 90, height: 63}} /> */}
                </div>
              ))}
          </Slider>
        )}
      </div>
    );
  }
}
