import React, { useEffect, useState } from "react";
import styles from "./Contests.module.scss";
import MainContainerLeftControl from "../../universal_components/MainContainerLeftControl/MainContainerLeftControl";
import { useDispatch, useSelector } from "react-redux";
import { getContests } from "../../../requests/getContests";
import ContestsItem from "./ContestsItem";

const Contests = () => {
  const [contests, setContests] = useState();
  const contestsRedux = useSelector((state) => state.contests.contests);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContests());
  }, [dispatch]);

  const sortContestsByEndDate = (array) => {
    array.sort((a, b) => (a.finish_date > b.finish_date ? 1 : -1));
  };

  useEffect(() => {
    if (!contestsRedux) return;
    setContests(
      contestsRedux.sort((a, b) => (a.finish_date < b.finish_date ? 1 : -1))
    );
  }, [contestsRedux]);

  const options = "280px";
  return (
    <div className={styles.contests}>
      <MainContainerLeftControl pageName={"КОНКУРСЫ"} widthAside={options}>
        {contests && contests.length > 0 && (
          <div className={styles.contests_wrap}>
            {contests.map((item, index) => (
              <ContestsItem key={index} item={item} />
            ))}
          </div>
        )}
      </MainContainerLeftControl>
    </div>
  );
};

export default Contests;
