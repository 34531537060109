import { getMPsAction } from '../redux/Reducers/MPsReducer';
import { BASE_URL } from './BASE_URL';

export const getMPs = () => {
  return async (dispatch) => {
    const response = await fetch(
      BASE_URL + 'master-plan/master-plan-territory/'
    );
    const data = await response.json();

    if (response.status === 200) {
      dispatch(getMPsAction(data));
    }
  };
};
