import React from 'react';
import MainContainerLeftControl from '../../universal_components/MainContainerLeftControl/MainContainerLeftControl';
import styles from './Objects.module.scss';
import ObjectsAside from './ObjectsAside/ObjectsAside';
import ObjectsContentItem from './ObjectsContent/ObjectsContentItem/ObjectsContentItem';
// import image from '../../../images/test-town_6.jpg';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFiltersAction } from '../../../redux/Reducers/objectFiltersReducer';

const ObjectInfoPage = () => {
  const [currentObject, setCurrentObjetc] = useState();
  const [mockArray, setMockArray] = useState();
  const params = useParams();
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.object_filters.filters);
  const [filteredArray, setFilteredArray] = useState();

  const options = '383px';
  const objects = useSelector((state) => state.objects.objects);

  useEffect(() => {
    if (filter.programm.length || filter.territory || filter.years.length) {
      dispatch(getFiltersAction(JSON.parse(sessionStorage.getItem('filter'))));
    }
  }, []);

  useEffect(() => {
    if (!objects) return;
    setMockArray(objects);
  }, [objects]);

  const getFiltereObjects = (array, isFiltered) => {
    if (isFiltered) setFilteredArray(array);
    if (!isFiltered) setFilteredArray();
  };

  useEffect(() => {
    if (!mockArray) return;
    const activeItem = mockArray.filter(
      (item) => Number(item.id) === Number(params.id)
    );
    setCurrentObjetc(activeItem[0]);
  }, [params, mockArray]);

  return (
    <div className={styles.objects}>
      {mockArray && (
        <MainContainerLeftControl
          widthAside={options}
          data={<ObjectsAside data={mockArray} filter={filter} />}
        >
          {currentObject && (
            <div className={styles.content}>
              <ObjectsContentItem currentObject={currentObject} />
            </div>
          )}
        </MainContainerLeftControl>
      )}
    </div>
  );
};

export default ObjectInfoPage;
