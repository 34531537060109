import { useState } from 'react';
import Footer from './Footer/Footer';
import HeaderWrapper from './HeaderWrapper/HeaderWrapper';
import MPs from './MPs/MPs';

import styles from './MasterPlan.module.scss';
import { useEffect } from 'react';
import { getMPMainText } from '../../../requests/getMPMainText';

const MasterPlan = () => {
  const [text, setText] = useState();

  useEffect(() => {
    getMPMainText().then((data) => setText(data));
  }, []);
  if (!text) return false;

  return (
    <div className={styles.wrapper}>
      <HeaderWrapper text={text.find((item) => item.id == 2)} />
      <MPs
        text={text.find((item) => item.id == 1)}
        text2={text.find((item) => item.id == 3)}
      />
      <Footer />
    </div>
  );
};

export default MasterPlan;
