import React, { useRef, useState } from "react";
import { useEffect } from "react";
import DescriptionItem from "./DescriptionItem/DescriptionItem";
import styles from "./OtherVacancies.module.scss";

const OtherVacancies = ({ vacancies }) => {
  const [vacancyBlockHeight, setVacancyBlockHeight] = useState(20);
  const elemsRef = useRef();

  useEffect(() => {
    let maxHeight = 0;

    if (elemsRef.current) {
      elemsRef.current.childNodes.forEach((item, index) => {
        index < 4 && (maxHeight += item.clientHeight);
        console.dir(item.clientHeight)
      });
    }

    setVacancyBlockHeight(maxHeight);
  }, [elemsRef, vacancies]);

  return (
    vacancies && vacancies.length > 0 && (
      <div className={styles.other}>
      <p>Все вакансии</p>

      <div
        ref={elemsRef}
        style={{ minHeight: vacancyBlockHeight + 'px', maxHeight: vacancyBlockHeight + "px", overflowY: "auto" }}
      >
        {vacancies.map((item, index) => (
            <DescriptionItem
              key={index}
              id={item.id}
              name={item.position}
              requirements={item.requirements}
            />
          ))}
      </div>
    </div>
    )
    
  );
};

export default OtherVacancies;
