import React, { useEffect } from "react";
import NewsItem from "./NewsItem/NewsItem";
import styles from "./NewsList.module.scss";


const NewsList = ({ news }) => {

  // console.log(news)


  // useEffect(() => {
  //   document.addEventListener('scroll', scrollHandler)

  //   return function () {
  //     document.removeEventListener('scroll', scrollHandler)
  //   }
  // }, [])

  // const scrollHandler = (evt) => {
  //   if(evt.target.documentElement.scrollHeight - (evt.target.documentElement.scrollTop + window.innerHeight) < 100) {
  //     console.log('scroll');
  //   }
  //   console.log('scrollHeight', evt.target.documentElement.scrollHeight)
  //   console.log('scrollTop', evt.target.documentElement.scrollTop)
  //   console.log('innerHeight', window.innerHeight)
  // }


  return (
    <ul className={styles.list}>
      {news.map((item) => {
        // console.log(item)
        return (
          <NewsItem
            key={item.id}
            id={item.id}
            title={item.title}
            img={item.images[0].cropped_image}
            date={item.date_to_show}
          />
        );
      })}
    </ul>
  );
};

export default NewsList;
