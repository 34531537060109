import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import DG from '2gis-maps';

import logo from '../../../../images/logo-blue.svg';
import iconMarker from '../../../../images/map_marker.svg';

import './MapMP.scss';

import styles from './MapMP.module.scss';
import defaultImage from '../../../../images/object_mask_profile.svg';
import useWindowWidth from '../../../../hooks/useWindowWidth';

const MapMP = ({ ideas, id }) => {
  const [activeMarker, setActiveMarker] = useState();
  const width = useWindowWidth();
  const location = useLocation();

  const ref = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash == '#map') {
      ref.current.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    if (!ideas) return;
    const locArray = [];
    ideas.map((idea) =>
      idea.idea_marker.map((item) =>
        locArray.push({
          name: item.title,
          lat: item.marker_latitude,
          lon: item.marker_longitude,
          id: idea.id,
        })
      )
    );

    let map = DG.map('map', {
      center: [68.008758, 92.870314],
      zoom: 5,
    });

    let icon = DG.icon({
      iconUrl: iconMarker,
      iconSize: [30, 30],
    });

    locArray.map((item) => {
      DG.marker([item.lat, item.lon], { icon: icon })
        .on('click', (e) => {
          setActiveMarker([e.latlng.lat, e.latlng.lng]);
        })
        .addTo(map)
        .bindPopup(
          `<a href="/masterplan/${id}/ideas/${item.id}` +
            '"<p>' +
            item.name +
            '</p>' +
            '</a>'
        );
    });
    return () => map.remove();
  }, [ideas]);

  return (
    <div ref={ref} className={styles.wrapper}>
      <ul className={styles.left}>
        {ideas?.map((idea) => (
          <li
            onClick={() => navigate(`/masterplan/${id}/ideas/${idea.id}`)}
            className={styles.idea}
            key={idea.id}
          >
            <p>{idea.title}</p>
            <img src={idea.image ? idea.image : defaultImage} />
          </li>
        ))}
      </ul>
      <div className={styles.mapWrapper}>
        <div className={styles.map}>
          <div
            id='map'
            style={{ width: '100%', height: 414, border: '2px solid #00405b' }}
          ></div>
          <img
            className={styles.decorImg}
            src={logo}
            alt=''
            width='138'
            height='138'
          />
        </div>
      </div>
    </div>
  );
};

export default MapMP;
