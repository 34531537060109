import React from "react";
import styles from "./SearchPurchases.module.scss";
import { useEffect } from "react";

const SearchPurchases = ({ onChange, value, activeFilter }) => {
  return (
    <div className={styles.search}>
      <input
        onChange={(e) => onChange(e.target.value)}
        className={styles.input}
        value={value}
        type="text"
        placeholder="Введите наименование организации"
      />
    </div>
  );
};

export default SearchPurchases;
