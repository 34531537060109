import React, { useEffect, useState } from 'react';
import styles from './ObjectItemInfo.module.scss';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ObjectItemInfo = ({ currentObject }) => {
  const [news, setNews] = useState([]);
  const events = useSelector((state) => state.news.events);
  const media = useSelector((state) => state.news.media);

  useEffect(() => {
    if (!events || !media) return;
    const news = [];
    const objectEvents = events.filter((event) => {
      for (let i = 0; i < currentObject.news.length; i++) {
        if (event.id === currentObject.news[i]) return true;
      }
    });
    const objectMedia = media.filter((media) => {
      for (let i = 0; i < currentObject.news.length; i++) {
        if (media.id === currentObject.news[i]) return true;
      }
    });
    setNews([...objectEvents, ...objectMedia]);
  }, []);
  return (
    <div className={styles.content}>
      <div className={styles.info}>
        <h3>Информация</h3>
        <ul>
          <li>
            <p>
              Статус:&nbsp;<span>{currentObject.status}</span>
            </p>
          </li>

          <li>
            <p>
              Финансирование:&nbsp;
              <span>{currentObject.financing_source.name}</span>
            </p>
          </li>

          {currentObject.project_organization && (
            <li>
              <span>
                Проект:&nbsp;
                <span>{currentObject.project_organization}</span>&nbsp;
              </span>
              {/* <span>2020</span> */}
            </li>
          )}

          {currentObject.contactor && (
            <li>
              <span>
                Подрядчик:&nbsp;
                <span>{currentObject.contactor}</span>&nbsp;
              </span>
              {/* <span>{currentObject.year.year}</span> */}
            </li>
          )}
          {news.length > 0 && (
            <li>
              <span className={styles.news}>
                Подробнее:&nbsp;
                {news.map((item) => (
                  <Link
                    key={item.id}
                    to={
                      `/news/${
                        item.category == 'event' ? 'events' : 'media'
                      }/` + item.id
                    }
                  >
                    {item.title}
                  </Link>
                ))}
              </span>
            </li>
          )}
        </ul>
        {isMobile &&
          currentObject.documents &&
          currentObject.documents.length > 0 && (
            <div className={styles.documentsList}>
              <h3>Документы</h3>
              {currentObject.documents.map((item, index) => (
                <a key={index} href={item.document} target='_blank'>
                  {item.title}
                </a>
              ))}
            </div>
          )}
      </div>

      {isMobile && currentObject.description && (
        <div className={styles.desc}>
          <h3>Описание</h3>
          <p>{currentObject.description}</p>
        </div>
      )}
    </div>
  );
};

export default ObjectItemInfo;
