import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './AggregatorTable.module.scss';
import SecondaryInfo from './SecondaryInfo/SecondaryInfo';
import MainInfo from './MainInfo/MainInfo';
import AggregatorItem from './AggregatorItem/AggregatorItem';

import { ReactComponent as SortIcon } from '../../../../../../images/purchases-sort-icon.svg';

const AggregatorTable = ({ data, searchFiled, activeFilter }) => {
  const [filteredArray, setFilteredArray] = useState();
  const [dataLocal, setDataLocal] = useState();
  const [isSorted, setSorted] = useState(false);
  useEffect(() => {
    if (!data) return;
    const arr = data.sort((a, b) =>
      Date.parse(a.finish_date) < Date.parse(b.finish_date) ? 1 : -1
    );
    const sorted = arr.sort((a, b) =>
      Date.parse(a.finish_date) < Date.parse(b.finish_date) ? 1 : -1
    );
    setDataLocal(sorted);
  }, [data]);

  useEffect(() => {
    if (!activeFilter) {
      setDataLocal(data);
      setFilteredArray(data);
      return;
    }
    const arr = data.filter((item) => item.status === activeFilter);
    setDataLocal(arr);
    setFilteredArray(arr);
  }, [activeFilter, dataLocal]);

  useEffect(() => {
    if (!dataLocal) return;
    if (!searchFiled || searchFiled === '') {
      if (!isSorted) {
        setFilteredArray();
        const arr = dataLocal.sort((a, b) =>
          Date.parse(a.finish_date) > Date.parse(b.finish_date) ? 1 : -1
        );
        setDataLocal(arr);
      } else {
        const arr = dataLocal.sort((a, b) =>
          Date.parse(a.finish_date) < Date.parse(b.finish_date) ? 1 : -1
        );
        setDataLocal(arr);
      }
    } else {
      const filtered = dataLocal.filter((item) =>
        item.customer.name.toLowerCase().includes(searchFiled.toLowerCase())
      );
      if (!isSorted) {
        filtered.sort((a, b) =>
          Date.parse(a.finish_date) > Date.parse(b.finish_date) ? 1 : -1
        );
        setFilteredArray(filtered);
      } else {
        filtered.sort((a, b) =>
          Date.parse(a.finish_date) < Date.parse(b.finish_date) ? 1 : -1
        );
        setFilteredArray(filtered);
      }
    }
  }, [searchFiled, dataLocal, isSorted]);

  return (
    <ul className={styles.table}>
      <li className={classnames([styles.item, styles.head])}>
        <div className={styles.inner}>
          <p>Заказчик</p>
          <p>Предмет закупки</p>
          <p className={styles.sorted}>
            Срок проведения{' '}
            <SortIcon
              style={isSorted ? { transform: 'rotate(180deg)' } : {}}
              onClick={() => setSorted(!isSorted)}
            />
          </p>
          <p>Статус закупки</p>
          <p>Стоимость лотов</p>
        </div>
      </li>

      {dataLocal ? (
        filteredArray ? (
          filteredArray.length > 0 ? (
            filteredArray.map((item, index) => (
              <AggregatorItem key={index} data={item} />
            ))
          ) : (
            <AggregatorItem data={false} />
          )
        ) : dataLocal.length > 0 ? (
          dataLocal.map((item, index) => (
            <AggregatorItem key={index} data={item} />
          ))
        ) : (
          <AggregatorItem data={false} />
        )
      ) : (
        <AggregatorItem data={false} />
      )}
    </ul>
  );
};

export default AggregatorTable;
