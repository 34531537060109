import React from "react";
import styles from "./DecorLogo.module.scss";
import decorLogo from "../../../../../images/logo-white.svg";
import classnames from "classnames";

const DecorLogo = (props) => {
  return (
    <div
      className={classnames(
        props.isShowMenu ? [styles.logo, styles.open] : [styles.logo]
      )}
    >
      <img src={decorLogo} alt="" width="110" height="110" />
    </div>
  );
};

export default DecorLogo;
