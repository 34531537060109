import React from "react";
import MainContainerLeftControl from "../../universal_components/MainContainerLeftControl/MainContainerLeftControl";
import styles from "./Purchases.module.scss";
import PurchasesAside from './PurchasesAside/PurchasesAside';
import PurchasesContent from './PurchasesContent/PurchasesContent';
import { useSelector } from 'react-redux';
import { useEffect } from "react";
import { useState } from "react";
import PurchasesAggregator from './PurchasesContent/PurchasesAggregator/PurchasesAggregator';
import { useParams } from 'react-router-dom';
import PurchasesRegistr from './PurchasesContent/PurchasesRegistr/PurchasesRegistr';
import { isMobile } from 'react-device-detect';
import RigisrtryPurchasesAside from "./PurchasesAside/RigisrtryPurchasesAside";

const PurchasesRegistry = () => {
  const [data, setData] = useState();

  const organizations = useSelector(state => state.organizations.organizations);
  const { type } = useParams();

  useEffect(() => {
    if (!organizations) return;
    if (type === 'project') {
      const projectOrg = organizations.filter(item => item.category === 'design');
      setData(projectOrg);
    }
    if (type === 'local') {
      const localOrg = organizations.filter(item => item.category === 'local');
      setData(localOrg)
    }
    if (type === 'contractor') {
      const contractorOrg = organizations.filter(item => item.category === 'contractor');
      setData(contractorOrg);
    }
  }, [organizations, type])
  // ширина боковой колонки
  const options = "300px";

  return (
    <div className={styles.purch}>
      <MainContainerLeftControl widthAside={options} data={<RigisrtryPurchasesAside /> }>
        {isMobile && <h2 className="title"></h2>}
        <PurchasesContent children={<PurchasesRegistr data={data} />} />
      </MainContainerLeftControl>
    </div>
  );
};

export default PurchasesRegistry;
