const initialState = {
    partners: [],
}

const GET_PARTNTERS = 'GET_PARTNTERS';

export const partnersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PARTNTERS:
            return {...state,
                partners: action.payload,
            }
        default:
            return state;
    }
}

export const getPartnersAction = (payload) => ({type: GET_PARTNTERS, payload})