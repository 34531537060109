import React from "react";
import styles from "./ObjectItemHead.module.scss";
import { isMobile } from 'react-device-detect';

const ObjectItemHead = ({ currentObject }) => {
  return (
    <div className={styles.head}>
      <h2 className={styles.title}>{currentObject.name}</h2>

      <p className={styles.street}>{currentObject.address}</p>

      {currentObject.video_link && (
        <a className={styles.video} href={currentObject.video_link} target="_blank">
          Смотреть видео
        </a>
      )}
    </div>
  );
};

export default ObjectItemHead;
