import React from "react";
import styles from "./MainContainerRightControl.module.scss";

import Aside from "../Aside/Aside";
import Search from "../Search/Search";
import PageLogo from "../logo/PageLogo/PageLogo";
import { isMobile } from "react-device-detect";

const MainContainerRightControl = (props) => {
  return (
    <>

      {!isMobile && (
        <div className={styles.search}>
          <PageLogo />
          <Search onChange={props.onChange} />
        </div>
      )}

      <div className={styles.rightControl}>
        <div className={styles.aside}>
      {/* {isMobile && <h3 style={{fontSize: 21, fontWeight: 600, marginBottom: 24}}>{props.pageName}</h3>} */}

          <Aside
            widthAside={props.widthAside}
            data={props.data}
            pageName={props.pageName}
            onChange={props.onChange}
          />
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
    </>
  );
};

export default MainContainerRightControl;
