import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./VacancyDescription.module.scss";
import OtherVacancies from '../OtherVacancies/OtherVacancies';
import { isMobile } from 'react-device-detect';

const VacancyDescription = ({ vacancy, vacancies }) => {
  const [localVacancy, setLocalVacancy] = useState();
  
  const separateText = (text) => {
    if (text) {
      return text.split("\r\n");
    } else {
      return []
    }
  };
  
  useEffect(() => {
    if (!vacancy) return;
    setLocalVacancy(vacancy);
  }, [vacancy])

  return (
    <div className={styles.vacancy}>
      {localVacancy && Object.keys(localVacancy).length ? (
        <>
          <h2>{localVacancy.position}</h2>

          <ul className={styles.list}>
            <li>
              <h3>Обязанности</h3>
              {separateText(localVacancy.responsibilities).map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </li>
            <li>
              <h3>Требования</h3>
              {separateText(localVacancy.requirements).map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </li>
            <li>
              <h3>Условия</h3>
              {separateText(localVacancy.conditions).map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </li>
          </ul>
        </>
      ) : (
        <h2>Нет соответствующих вакансий</h2>
      )}
      {vacancies && isMobile && (<OtherVacancies vacancies={vacancies} />)}
    </div>
  );
};

export default VacancyDescription;
