import { useNavigate, useParams } from 'react-router-dom';
import ButtonBack from '../../../../universal_components/ButtonBack/ButtonBack';
import Footer from '../../../MasterPlan/Footer/Footer';
import styles from './AllIdeas.module.scss';
import Header from '../../../../universal_components/header/Header';
import { ReactComponent as Logo } from '../../../../../images/logo-big.svg';
import { useEffect, useState } from 'react';
import { getMPIdea } from '../../../../../requests/getMPIdea';
import useWindowWidth from '../../../../../hooks/useWindowWidth';

import { useDispatch, useSelector } from 'react-redux';
import defaultImage from '../../../../../images/object_mask_profile.svg';
import setCharAt from '../../../../../functions/setCharAt';

const AllIdeas = () => {
  const { id, idIdea } = useParams();
  const width = useWindowWidth();
  const dispatch = useDispatch();

  const [idea, setIdea] = useState();
  const navigate = useNavigate();
  const MPs = useSelector((state) => state.MPs.MPs);
  const ideasRedux = useSelector((state) => state.MPIdeas.ideas);

  useEffect(() => {
    if (!ideasRedux) {
      dispatch(getMPIdea(id));
    } else {
      setIdea(ideasRedux.find((idea) => idea.id == idIdea));
    }
  }, [ideasRedux, id, idIdea]);

  if (!ideasRedux || !idea) return false;

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Header />
        <div className={styles.wrapper}>
          <Logo onClick={() => navigate('/')} className={styles.logo} />
          <div className={styles.allIdeas}>
            <div className={styles.content}>
              {width > 1000 && (
                <ul className={styles.left}>
                  {ideasRedux.map((item) => (
                    <li
                      onClick={() =>
                        navigate(`/masterplan/${id}/ideas/${item.id}`)
                      }
                      className={styles.idea}
                      key={item.id}
                      style={{
                        backgroundColor: item.id == idea.id ? '#eff2f3' : '',
                      }}
                    >
                      <p>{item.title}</p>
                      <img src={item.image ? item.image : defaultImage} />
                    </li>
                  ))}
                </ul>
              )}
              <div className={styles.right}>
                <div className={styles.title}>
                  <h2>{idea.title}</h2>
                </div>
                <img
                  className={styles.image}
                  src={idea.image ? idea.image : defaultImage}
                />
                <div className={styles.info}>
                  <h3>Информация</h3>
                  <ul>
                    <li>
                      <p>
                        Статус:&nbsp;<span>{idea.social_status}</span>
                      </p>
                    </li>

                    <li>
                      <p>
                        Автор:&nbsp;
                        <span>{idea.full_name}</span>
                      </p>
                    </li>

                    {idea.team && (
                      <li>
                        <span>
                          Команда:&nbsp;
                          <span>{idea.team}</span>
                          &nbsp;
                        </span>
                      </li>
                    )}
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: setCharAt(idea.description, 3, 'Суть идеи: '),
                        }}
                      ></span>
                    </li>
                  </ul>
                </div>
                <div className={styles.backButton}>
                  <ButtonBack to={`/masterplan/${id}#map`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AllIdeas;
