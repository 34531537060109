import React from 'react';
import styles from './ButtonBack.module.scss';
import classnames from 'classnames';
import { hasFormSubmit } from '@testing-library/user-event/dist/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { ReactComponent as ArrowBack } from '../../../images/arrow-left.svg';
import { ReactComponent as ArrowBackMobile } from '../../../images/arrow-top.svg';

const ButtonBack = ({ to, text = 'Назад' }) => {
  const navigation = useNavigate();
  const { pathname } = useLocation();

  const windowWidth = window.innerWidth;
  // работа кнопки в проекте мужских хоров

  // document.addEventListener('scroll', () => {
  //   const upButton = document.querySelector('.header__up-button');
  //   if (window.pageYOffset > window.innerHeight) {
  //     upButton.classList.remove('hidden');
  //   } else {
  //     upButton.classList.add('hidden');
  //   }
  // });
  // document.querySelector('.header__up-button').addEventListener('click', () => {
  //   let currentScroll = window.pageYOffset;
  //   let intervalID = setInterval(() => {
  //     currentScroll -= 30;
  //     window.scrollTo(0, currentScroll);
  //     if (currentScroll < 0) {
  //       clearInterval(intervalID);
  //     }
  //   }, 0);
  // });

  let scrollToTop = () => {
    const anchors = document.querySelector('.title');
    // const anchor = anchors.querySelectorAll('button');

    // let anchor
    // anchors.forEach(item => {
    //   if (item.className.includes('Burger')) {
    //     anchor = item;
    //   }
    // })

    anchors.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };
  return (
    <>
      {isMobile ? (
        pathname.includes('/purchases') ||
        pathname === '/about/company' ||
        pathname === '/about/workers' ||
        pathname === '/about/vacancy' ||
        pathname === '/news/events' ||
        pathname === '/news/media' ||
        pathname === '/objects' ||
        pathname === '/documents' ? (
          <div className={styles.mobileBackButton}>
            <Link
              className={classnames(styles.back, styles.down)}
              style={{ transform: 'rotate(0deg)' }}
              data-text={text}
              onClick={(e) => {
                e.preventDefault();
                scrollToTop();
              }}
            >
              <ArrowBackMobile />
            </Link>
          </div>
        ) : (
          <div className={styles.mobileBackButton}>
            {to ? (
              <Link
                className={classnames(styles.back, styles.down)}
                data-text={text}
                to={to}
              >
                <div style={{ transform: 'rotate(-90deg)' }}>
                  <ArrowBackMobile />
                </div>{' '}
              </Link>
            ) : (
              <a
                href='#'
                className={classnames(styles.back, styles.down)}
                data-text={text}
                onClick={(e) => {
                  e.preventDefault();
                  navigation(-1);
                }}
              >
                <div style={{ transform: 'rotate(-90deg)' }}>
                  <ArrowBackMobile />
                </div>{' '}
              </a>
            )}
          </div>
        )
      ) : to?.includes('/masterplan') ? (
        <Link
          className={classnames(styles.back, styles.down, styles.backRight)}
          data-text={text}
          to={to}
        >
          <ArrowBack />
          <p>{text}</p>
        </Link>
      ) : to ? (
        <Link
          className={classnames(styles.back, styles.down)}
          data-text={text}
          to={to}
        >
          <ArrowBack />
          <p>{text}</p>
        </Link>
      ) : (
        <div
          href='#'
          className={classnames(styles.back, styles.down)}
          style={{ cursor: 'pointer' }}
          data-text={text}
          onClick={(e) => {
            navigation(-1);
          }}
        >
          <ArrowBack />
          <p>{text}</p>
        </div>
      )}
    </>
  );
};

export default ButtonBack;
