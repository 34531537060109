const initialState = {
  contests: false,
};

const GET_CONTESTS = "GET_CONTESTS";

export const contestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTESTS:
      return { ...state, contests: action.payload };
    default:
      return state;
  }
};

export const getContestsAction = (payload) => ({ type: GET_CONTESTS, payload });
