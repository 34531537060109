import { useState } from 'react';
import styles from './Docs.module.scss';
import { useRef } from 'react';
import classNames from 'classnames';

const Docs = ({ docs }) => {
  const [open, setOpen] = useState(false);

  const list = useRef();
  console.log('docs -> ', docs)

  return (
    <div id='docs' className={styles.docs}>
      <button
        className={classNames(styles.doc, {
          [styles.docActive]: open,
        })}
        onClick={() => {
          setOpen(!open);
        }}
      >
        СТРУКТУРА МАСТЕР-ПЛАНА
      </button>
      <ol
        style={open ? { height: list.current?.scrollHeight + 'px' } : {}}
        ref={list}
        className={styles.list}
      >
        {docs.chapter.map((doc, index) => (
          <li className={styles.docWrapper} key={index}>
            <div className={styles.docTitle}>
              {doc.title}
              {
                doc?.document.length > 0 && (
                  <ul className={styles.sublist}>
                    {
                      doc.document.map((item, index) => {
                        return <li key={index}>
                          <a href={item.doc} target='_blank'><span>&bull;</span>&nbsp;{item.title}</a> 
                        </li>
                      })
                    }
                  </ul>
                )
              }
            </div>

          </li>
        ))}
      </ol>
    </div>
  );
};

export default Docs;
