import React from 'react';
import ObjectContentFilters from './ObjectContentFilters/ObjectContentFilters';
import ObjectMap from './ObjectMap/ObjectMap';
import styles from './ObjectsContentControl.module.scss';

const ObjectsContentControl = ({
  filter,
  getActiveMarker,
  filteredArray,
}) => {
  return (
    <div className={styles.control}>
      <ObjectContentFilters 
      filter={filter}
       />
      <ObjectMap
        getActiveMarker={getActiveMarker}
        filteredArray={filteredArray}
      />
    </div>
  );
};

export default ObjectsContentControl;
