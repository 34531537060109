import React from "react";
import MainContainerRightControl from "../../universal_components/MainContainerRightControl/MainContainerRightControl";
import styles from "./Docum.module.scss";
import DocumAside from "./DocumAside/DocumAside";
import DocumContent from "./DocumContent/DocumContent";
import MainContainerLeftControl from "../../universal_components/MainContainerLeftControl/MainContainerLeftControl";
import { useState } from "react";
import { isMobile } from "react-device-detect";

const Docum = () => {
  // ширина боковой колонки
  const options = "280px";

  const [filterArray, setFilterArray] = useState([]);
  const [searchField, setSearchField] = useState();

  const onFilterChange = (filters) => {
    setFilterArray(filters);
  };

  const onSearchChange = (value) => {
    setSearchField(value);
  };

  return (
    <div className={styles.docum}>
      <MainContainerRightControl
        pageName={"ДОКУМЕНТАЦИЯ"}
        onChange={onSearchChange}
        widthAside={options}
        data={<DocumAside onFilterChange={onFilterChange} />}
      >
        {isMobile && <h2 className="title"></h2>}
        <DocumContent filterArray={filterArray} searchField={searchField} />
      </MainContainerRightControl>
    </div>
  );
};

export default Docum;
