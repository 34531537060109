import React from "react";
import styles from "./SecondaryInfo.module.scss";

const SecondaryInfo = ({ description, customer, improvement_objects, documents }) => {
  return (
    <div className={styles.secondaryInfo}>
      <div className={styles.organization}>
        <h3>{customer.name}</h3>
        <p>
          {description}
        </p>
      </div>

      {/* {improvement_objects.length > 0 && (
        <div className={styles.objects}>
        <h4>Объекты</h4>
        {improvement_objects.map((item, index) => <a href={item.link}>{item.name}</a>) }
      </div>
      )} */}
      
      {documents && (
        <div className={styles.docum}>
        <h4>Документы</h4>
        <div className={styles.links}>
          {documents.map((item, index) => <p key={index}><a href={item.file} target="_blank">{item.name}</a></p>)}
        </div>
      </div>
      )}
      
    </div>
  );
};

export default SecondaryInfo;