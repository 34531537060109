import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./PurchasesAside.module.scss";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { useManufactureFilter } from "../../../../store/store";

const RigisrtryPurchasesAside = () => {
  const [agregatorType, setAgregatorType] = useState("ano");
  const [registryType, setRegistryType] = useState("project");
  const setManufacture = useManufactureFilter(state => state.setManufacture)
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   if (!location.pathname.includes('agregator')) return;
  //   navigate('/purchases/agregator/' + agregatorType);
  // }, [agregatorType])

  useEffect(() => {
    if (!location.pathname.includes("registry")) return;
    navigate("/registry/" + registryType);
  }, [registryType]);

  return (
    <div className={styles.aside}>
      <ul className={styles.list}>
        {/* <li className={styles.item}>
          <NavLink to={'/purchases/info'} className={location.pathname === '/purchases/info' ? `${styles.button} ${styles.pressed}` : `${styles.button}`}>Порядок закупок АНО</NavLink>
        </li>
        <li className={styles.item}>
          <NavLink to={'/purchases/agregator/ano'} className={location.pathname.includes('agregator') ? `${styles.button} ${styles.pressed}` : `${styles.button}`}>
            Агрегатор закупок
          </NavLink>

          <ul className={styles.filterbox}>
            <li className={styles.filteritem} style={agregatorType === 'ano' ? {backgroundColor: '#EFF2F4'} : {}}>
              <label>
                <input
                  type="radio"
                  className="visually-hidden"
                  name="agregator"
                  defaultChecked="checked"
                  value="contest_ano"
                  onChange={e => e.target.checked && setAgregatorType('ano')}
                />
                <span className={styles.mark}></span>
                <span className={styles.name}>Конкурсы АНО</span>
              </label>
            </li>

            <li className={styles.filteritem} style={agregatorType === 'city' ? {backgroundColor: '#EFF2F4'} : {}}>
              <label>
                <input
                  type="radio"
                  className="visually-hidden"
                  name="agregator"
                  value="contest_municipal"
                  onChange={e => e.target.checked && setAgregatorType('city')}
                />
                <span className={styles.mark}></span>
                <span className={styles.name}>Конкурсы муниципалитетов </span>
              </label>
            </li>

            <li className={styles.filteritem} style={agregatorType === 'regions' ? {backgroundColor: '#EFF2F4'} : {}}>
              <label>
                <input
                  type="radio"
                  className="visually-hidden"
                  name="agregator"
                  value="contest_regional"
                  onChange={e => e.target.checked && setAgregatorType('regions')}
                />
                <span className={styles.mark}></span>
                <span className={styles.name}>Краевые конкурсы</span>
              </label>
            </li>
          </ul>
        </li> */}

        <li className={styles.item}>
          <div
            to={"/purchases/registry/project"}
            className={
              location.pathname.includes("registry")
                ? `${styles.button} ${styles.pressed}`
                : `${styles.button}`
            }
          >
            Реестр поставщиков <br></br> и подрядчиков
          </div>

          <ul className={styles.filterbox}>
            <li
              className={styles.filteritem}
              style={
                registryType === "project" ? { backgroundColor: "#EFF2F4" } : {}
              }
            >
              <label>
                <input
                  type="radio"
                  className="visually-hidden"
                  name="registry"
                  value="project_organization"
                  defaultChecked="checked"
                  onChange={(e) =>
                    e.target.checked && setRegistryType("project")
                  }
                  // checked="checked"
                />
                <span className={styles.mark}></span>
                <span className={styles.name}>Проектные организации</span>
              </label>
            </li>

            <li
              className={styles.filteritem}
              style={
                registryType === "local" ? { backgroundColor: "#EFF2F4" } : {}
              }
            >
              <label>
                <input
                  type="radio"
                  className="visually-hidden"
                  name="registry"
                  value="local_fabricators"
                  onChange={(e) => e.target.checked && setRegistryType("local")}
                />
                <span className={styles.mark}></span>
                <span className={styles.name}>Производители элементов благоустройства</span>
              </label>
              {
                registryType === 'local' ? (
                  <ul >
                    <li className={styles.submenu}>
                      <label>
                        <input
                          type="checkbox"
                          className="visually-hidden"
                          name="registry"
                          value="local_fabricators"
                          defaultChecked={false}
                          onChange={() => setManufacture('krsk')}
                        />
                        <span className={styles.mark_check}></span>
                        <span className={styles.name}>Производители Красноярского края</span>
                      </label>
                    </li>
                    <li className={styles.submenu}>
                      <label>
                        <input
                          type="checkbox"
                          className="visually-hidden"
                          name="registry"
                          value="local_fabricators"
                          defaultChecked={false}
                          onChange={() => setManufacture('other')}
                        />
                        <span className={styles.mark_check}></span>
                        <span className={styles.name}>Производители других регионов РФ</span>
                      </label>
                    </li>
                  </ul>
                ) : null
              }
              </li>
              

            {/* <li
              className={styles.filteritem}
              style={
                registryType === "contractor"
                  ? { backgroundColor: "#EFF2F4" }
                  : {}
              }
            >
              <label>
                <input
                  type="radio"
                  className="visually-hidden"
                  name="registry"
                  value="contracting_organization"
                  onChange={(e) =>
                    e.target.checked && setRegistryType("contractor")
                  }
                />
                <span className={styles.mark}></span>
                <span className={styles.name}>Подрядные организации</span>
              </label>
            </li> */}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default RigisrtryPurchasesAside;
