import { getMediaAction, getEventsAction } from "../redux/Reducers/newsReducer";
import { BASE_URL } from "./BASE_URL";

export const getNews = () => {
  return async (dispatch) => {
    const response = await fetch(BASE_URL + "news/?limit=550");
    const data = await response.json();

    if (response.status === 200) {
      const events = data.results.filter((item) => item.category === "event");
      events && dispatch(getEventsAction(events));

      const media = data.results.filter((item) => item.category === "media");
      media && dispatch(getMediaAction(media));
    }
  };
};
