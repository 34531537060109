import { BASE_URL } from './BASE_URL';
import { getMPIdeasAction } from '../redux/Reducers/MPIdeasReducer';

export const getMPIdea =  (id) => {
  return async (dispatch) => {
    const response = await fetch(
      BASE_URL + `master-plan/idea/?master-plan=${id}`
    );
    const data = await response.json();

    if (response.status === 200) {
      dispatch(getMPIdeasAction(data));
    }
  };
};
