import React from "react";
import styles from "./Promo.module.scss";
import SliderMission from "../../universal_components/Slider-mission/SliderMission";

import textLogo from "../../../images/logo-text--dark.svg";
import { Link } from 'react-router-dom';

const Promo = () => {
  return (
    <section className={styles.promo}>
      <SliderMission />
      <Link to="/" className={styles.logo}>
        <img src={textLogo} alt="logo" width="197" height="109" />
      </Link>
    </section>
  );
};

export default Promo;
