const initialState = {
    supervisiory: [],
    text: '',
}

const GET_SUPERVISIORY = 'GET_SUPERVISIORY';
const GET_SUPERVISIORY_TEXT = 'GET_SUPERVISIORY_TEXT';

export const superVisioryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUPERVISIORY:
            return {...state,
                supervisiory: action.payload,
            }
        case GET_SUPERVISIORY_TEXT:
            return {...state,
                text: action.payload,
            }    
        default:
            return state;
    }
}

export const getSuperVisioryAction = (payload) => ({type: GET_SUPERVISIORY, payload})
export const getSuperVisioryTextAction = (payload) => ({type: GET_SUPERVISIORY_TEXT, payload})