const initialState = {
  MPs: [],
};

const GET_MPS = 'GET_MPS';

export const MPsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MPS:
      return { ...state, MPs: action.payload };
    default:
      return state;
  }
};

export const getMPsAction = (payload) => ({ type: GET_MPS, payload });
