import React, { Component } from 'react';
import Slider from 'react-slick';
import styles from './SliderMission.module.scss';
import classnames from 'classnames';

export default class SliderMission extends Component {
  render() {
    const settings = {
      dots: true,
      fade: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
    };
    return (
      <div
        className={classnames(
          this.props.location ? [styles.slider, styles.menu] : [styles.slider]
        )}
      >
        <Slider {...settings}>
          <div>
            <h2>Миссия</h2>
            <p>
              Создание территорий с новым качеством городской среды,
              инфраструктуры и сервиса путем формирования стратегии
              пространственного развития городов и населенных пунктов
              Красноярского края.
            </p>
          </div>

          <div>
            <h2>Цель</h2>
            <p>
              Содействие развитию градостроительной деятельности и
              пространственному развитию муниципальных образований, улучшению
              архитектурно-художественного облика городов и населенных пунктов
              края.
            </p>
          </div>

          <div>
            <h2>Задачи</h2>
            <p>
              Содействие органам местного самоуправления в повышении качества
              городской среды, разработка проектов в сфере территориального
              планирования, формирования комфортной городской среды и
              пространственного развития.
            </p>
          </div>
        </Slider>
      </div>
    );
  }
}
