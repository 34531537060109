import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./DocumContent.module.scss";
import DocumHead from "./DocumHead/DocumHead";
import DocumItem from "./DocumItem/DocumItem";

const DocumContent = ({ filterArray, searchField }) => {
  const [docs, setDocs] = useState();
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const documents = useSelector((state) => state.documents.documents);

  useEffect(() => {
    if (!documents) return;
    setDocs(documents);
  }, [documents]);

  useEffect(() => {
    if (filterArray.length < 1) {
      setIsFiltered(false);
    } else {
      setFilteredDocs(
        documents.filter((item) => filterArray.includes(item.category))
      );
      setIsFiltered(true);
    }
  }, [filterArray, searchField]);

  // Проверка по строке поиска

  useEffect(() => {
    if (!searchField) return;
    let filteredArray = [];
    if (isFiltered) {
      filteredArray = filteredDocs.filter((item) =>
        item.name.toLowerCase().includes(searchField.toLowerCase())
      );
    } else {
      filteredArray = docs.filter((item) =>
        item.name.toLowerCase().includes(searchField.toLowerCase())
      );
    }
    setFilteredDocs(filteredArray);
  }, [searchField, filterArray]);

  return (
    <div className={styles.content}>
      <ul>
        <DocumHead />
        {docs &&
          (isFiltered || (searchField && searchField.length > 0)
            ? filteredDocs.map((item, index) => (
                <DocumItem
                  key={index}
                  name={item.name}
                  document_type={item.document_type}
                  files={item.files}
                />
              ))
            : docs.map((item, index) => (
                <DocumItem
                  key={index}
                  name={item.name}
                  document_type={item.document_type}
                  files={item.files}
                />
              )))}
      </ul>
    </div>
  );
};

export default DocumContent;
