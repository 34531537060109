import React, { useState, useEffect } from "react";
import styles from "./NewsContent.module.scss";
// import { getNews } from "../../../../requests/getNews.js";

import NewsItemContent from "./NewsItemContent/NewsItemContent";
// import NewsList from "./NewsList/NewsList";
import MainContainerLeftControl from "../../../universal_components/MainContainerLeftControl/MainContainerLeftControl";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import NewsAsideItemControl from '../NewsAside/NewsAsideItemControl/NewsAsideItemControl';
import NewsAside from "../NewsAside/NewsAside";
import { isMobile } from 'react-device-detect';

const NewsContent = () => {
  const [currentNews, setCurrentNews] = useState();
  const [prevNews, setPrevNews] = useState();
  const [nextNews, setNextNews] = useState();
  const [typeOfNews, setTypeOfNews] = useState();
  const eventsItems = useSelector((state) => state.news.events);
  const mediaItems = useSelector((state) => state.news.media);
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (location.pathname.includes("events")) {
      if (!eventsItems) return;
      const eventsItem = eventsItems.filter((item) => item.id == params.id)[0];
      setCurrentNews(eventsItem);
      setTypeOfNews('events')
      const currentIndex = eventsItems.indexOf(eventsItem);
      const prev = currentIndex === 0 ? null : eventsItems[currentIndex - 1]
      const next = currentIndex === eventsItems[eventsItems.length - 1] ? null : eventsItems[currentIndex + 1]

      setPrevNews(prev)
      setNextNews(next)

    } else if (location.pathname.includes("media")) {
      if (!mediaItems) return;
      const mediaItem = mediaItems.filter((item) => item.id == params.id)[0];
      setCurrentNews(mediaItem);

      setTypeOfNews('media')
      const currentIndex = mediaItems.indexOf(mediaItem);
      const prev = currentIndex === 0 ? null : mediaItems[currentIndex - 1]
      const next = currentIndex === mediaItems[mediaItems.length - 1] ? null : mediaItems[currentIndex + 1]

      setPrevNews(prev)
      setNextNews(next)
    }
  }, [mediaItems, eventsItems, params]);

  return (
    <MainContainerLeftControl widthAside={340} data={<NewsAside type={typeOfNews} prev={prevNews} next={nextNews} currentId={currentNews && currentNews.id} /> }>
      <div className={styles.content}>
        {currentNews && <NewsItemContent item={currentNews} />}
        {isMobile && <NewsAsideItemControl type={typeOfNews} currentId={currentNews && currentNews.id} prev={prevNews} next={nextNews} />}
      </div>
    </MainContainerLeftControl>
  );
};

export default NewsContent;
