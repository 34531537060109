import React from "react";
import styles from "./MainInfo.module.scss";
import { formatDate } from "../../../../../../../functions/formatDate";
import { isMobile } from 'react-device-detect';
import ButtonClose from '../../../../../../universal_components/ButtonClose/ButtonClose';

const MainInfo = ({
  onChange,
  cost,
  customer,
  name,
  start_date,
  finish_date,
  status,
  isShow,
}) => {
  const windowWidth = window.innerWidth;
  return (
    <button className={styles.button} onClick={onChange}>
      {!customer ? (
        <div className={styles.empty}>
          <p>Нет соответствующих закупок</p>
        </div>
      ) : (
        <>
          <p className={styles.name}>{customer.name}</p>
          <p className={styles.object}>{name}</p>
          {(isMobile && windowWidth < 769) &&  <div className={styles.line}></div>}
          <p className={styles.dates}>
            <span>{formatDate(start_date)}</span>
            <span>-</span>
            <span>{formatDate(finish_date)}</span>
          </p>
          <p className={styles.status}>{status}</p>
          <p className={styles.cost}>{cost}р</p>
          {(isMobile) && <ButtonClose open={isShow} onChange={onChange} />}
        </>
      )}  
    </button>
  );
};

export default MainInfo;
