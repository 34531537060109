import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import SuperVisioryItem from "./SuperVisioryItem/SuperVisioryItem";

import styles from "./SuperVisiory.module.scss";

const SuperVisioryPage = () => {
  const [supervisiory, setSupervisiory] = useState();
  const [supervisioryText, setSupervisioryText] = useState();
  const supervisioryRedux = useSelector((state) => state.supervisiory.supervisiory);
  const supervisioryTextRedux = useSelector((state) => state.supervisiory.text);

  useEffect(() => {
    if (!supervisioryRedux) return;
    setSupervisiory(supervisioryRedux);
  }, [supervisioryRedux]);

  useEffect(() => {
    if (!supervisioryTextRedux) return;
    setSupervisioryText(supervisioryTextRedux);
  }, [supervisioryTextRedux]);

  return (
    <div className={styles.workersBox}>
      {supervisioryText && <p className={styles.mainDescription}>{supervisioryText}</p>}
      <div className={styles.workersWrapper}>
        {supervisiory &&
          supervisiory.map((item, index) => (
            <SuperVisioryItem
              key={index}
              photo={item.cropped_photo}
              fio={item.fio}
              position={item.description}
              email={item.email}
              phone={item.phone}
            />
          ))}
      </div>
    </div>
  );
};

export default SuperVisioryPage;
