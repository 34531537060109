const initialState = {
  news: [],
};

const GET_MEDIA = 'GET_NEWS';

export const MPNewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDIA:
      return { ...state, news: action.payload };
    default:
      return state;
  }
};

export const getNewsAction = (payload) => ({ type: GET_MEDIA, payload });
