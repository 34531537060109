import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../images/logo-big--white.svg';
import Header from '../../../universal_components/header/Header';

import styles from './HeaderWrapper.module.scss';

const HeaderWrapper = ({ text }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.header}>
      <Header />
      <div className={styles.textWrapper}>
        <Logo onClick={() => navigate('/')} className={styles.logo} />
        <h1>{text.title}</h1>
        <p
          dangerouslySetInnerHTML={{ __html: text.text }}
          className={styles.text}
        ></p>
      </div>
    </div>
  );
};

export default HeaderWrapper;
