import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import styles from "./NewsItemContentFilling.module.scss";

const NewsItemContentFilling = ({ text, documents, author, objects }) => {
  const [localObjects, setLocalObjects] = useState([]);
  const [localObject, setLocalObject] = useState([]);
  const objectsList = useSelector((state) => state.objects.objects);
  useEffect(() => {
    if (!objectsList) return;
    setLocalObjects(objectsList);
  }, [objectsList]);

  useEffect(() => {
    if (!localObjects) return;
    if (objects) {
      const array = [];
      objects.map((item) => {
        array.push(localObjects.filter((point) => point.id === item)[0]);
      });
      setLocalObject(array);
    }
  }, [localObjects, objects]);

  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <div className={styles.content}>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={createMarkup(text)}
      ></div>
      {documents && documents.length > 0 && (
        <div className={styles.document}>
          <span>Документ:</span>
          <div className={styles.documentList}>
            {documents.map((item) => (
              <a href={item.file} key={item.id}>
                {item.title}
              </a>
            ))}
          </div>
        </div>
      )}
      {localObject && localObject.length > 0 && (
        <div className={styles.document}>
          <span>Подробнее:</span>
          <div className={styles.documentList}>
            {localObject &&
              localObject.length > 0 &&
              localObject.map((item) => {
                if (!item) return;
                return (
                  <Link to={"/objects/" + item.id} key={item.id}>
                    {item.name}
                  </Link>
                );
              })}
          </div>
        </div>
      )}

      {author && (
        <div className={styles.copyright}>
          <span>Фото:</span>
          <span>{author}</span>
        </div>
      )}
    </div>
  );
};

export default NewsItemContentFilling;
