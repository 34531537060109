import React from "react";
import styles from "./NewsAside.module.scss";
import NewsAsideItemControl from "./NewsAsideItemControl/NewsAsideItemControl";
import NewsAsideListControl from "./NewsAsideListControl/NewsAsideListControl";

const NewsAside = ({ type, prev, next, currentId }) => {
  return (
    <div className={styles.aside}>
      <NewsAsideListControl
        type={type}
        prev={prev}
        next={next}
        currentId={currentId}
      />
    </div>
  );
};

export default NewsAside;
