import { getPurchasesAction } from "../redux/Reducers/purchasesReducer";
import { BASE_URL } from "./BASE_URL";

export const getPurchases = () => {
  return async (dispatch) => {
    const response = await fetch(BASE_URL + "purchases/aggregators/?limit=550");
    const data = await response.json();

    if (response.status === 200) {
      dispatch(getPurchasesAction(data.results));
    }
  };
};
