import React from 'react';
import styles from './Nav.module.scss';
import itemStyles from './ItemMenu/ItemMenu.module.scss';
import ItemMenu from './ItemMenu/ItemMenu';
import DecorLogo from './DecorLogo/DecorLogo';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

const Nav = (props) => {
  const location = useLocation();

  const [menuValues, setMenuValues] = useState([
    { link: '/', name: 'Главная', toggle: false },
    { link: '/about', name: 'О нас', toggle: false },
    { link: '/news', name: 'Новости', toggle: false },
    { link: '/objects', name: 'Объекты', toggle: false },
    { link: '/documents', name: 'Документация', toggle: false },
    { link: '/masterplan', name: 'Мастер-планы', toggle: false },
    { link: '/contests', name: 'Конкурсы', toggle: false },
    { link: '/purchases', name: 'Закупки', toggle: false },
    { link: '/registry', name: 'Подрядчики', toggle: false },
    { link: '/partners', name: 'Партнеры', toggle: false },
  ]);

  useEffect(() => {
    if (
      location.pathname.includes('/masterplan/') &&
      !location.pathname.includes('/ideas')
    ) {
      setMenuValues([
        { link: '/masterplan#news', name: 'Новости', toggle: false },
        { link: '#stages', name: 'Этапы', toggle: false },
        { link: '#docs', name: 'Документы', toggle: false },
        { link: '#photo', name: 'Фото', toggle: false },
      ]);
    } else if (location.pathname.includes('/ideas')) {
      setMenuValues([
        { link: '/masterplan#news', name: 'Новости', toggle: false },
        { link: '/masterplan', name: 'Мастер-планы', toggle: false },
      ]);
    }
  }, [location]);

  return (
    <nav
      className={classnames(
        props.isShowMenu ? [styles.nav, styles.open] : [styles.nav]
      )}
    >
      <ul>
        {menuValues.map((item, index) => (
          <ItemMenu
            key={index}
            toggleMenu={props.toggleMenu}
            isWhiteText={props.isWhiteText}
            isShowMenu={props.isShowMenu}
            name={item.name}
            link={item.link}
          />
        ))}
        {/* <li className={classnames(props.isShowMenu ? [itemStyles.item, itemStyles.open] : [itemStyles.item])}>
              <NavLink to={'/'} className={setActive}>Главная</NavLink>
            </li>
            <li className={classnames(props.isShowMenu ? [itemStyles.item, itemStyles.open] : [itemStyles.item])}>
              <NavLink to={'/about/company'} className={setActive} >О нас</NavLink>
            </li>
            <li className={classnames(props.isShowMenu ? [itemStyles.item, itemStyles.open] : [itemStyles.item])}>
              <NavLink to={'news/events'} className={setActive} >Новости</NavLink>
            </li> */}
        {/* <li className={classnames(props.isShowMenu ? [itemStyles.item, itemStyles.open] : [itemStyles.item])}>
              <NavLink to={'/objects'} className={setActive} >Объекты</NavLink>
            </li>
            <li className={classnames(props.isShowMenu ? [itemStyles.item, itemStyles.open] : [itemStyles.item])}>
              <NavLink to={'/documents'} className={setActive} >Документация</NavLink>
            </li>
            <li className={classnames(props.isShowMenu ? [itemStyles.item, itemStyles.open] : [itemStyles.item])}>
              <NavLink to={'/purchases'} className={setActive} >Закупки</NavLink>
            </li> */}
        {/* <li className={classnames(props.isShowMenu ? [itemStyles.item, itemStyles.open] : [itemStyles.item])}>
              <NavLink to={'/partners'} className={setActive} >Партнеры</NavLink>
            </li> */}
      </ul>
      <DecorLogo isShowMenu={props.isShowMenu} />
    </nav>
  );
};

export default Nav;
