import React from 'react';
import styles from './DocumItem.module.scss';

const DocumItem = ({ name, document_type, files }) => {
  const getExtention = (string) => {
    const indexDot = string.lastIndexOf('.');
    return string.slice(indexDot);
  };

  return (
    <li className={styles.item}>
      <div className={styles.type}>
        <p>{document_type}</p>
      </div>
      <div className={styles.name}>
        <p style={files.length < 1 ? { marginBottom: 0 } : {}}>{name}</p>
      </div>
      <div className={styles.documents}>
        {files &&
          files.map((item, index) => (
            <a className={styles.link} key={index} href={item.file}>
              {(item.name + getExtention(item.file)).replaceAll('_', ' ')}
            </a>
          ))}
      </div>
    </li>
  );
};

export default DocumItem;
