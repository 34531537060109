import React from "react";
import styles from "./DocumHead.module.scss";

const DocumHead = () => {
  return (
    <li className={styles.head}>
      <p className={styles.type}>Вид документа</p>
      <p className={styles.name}>Название документа</p>
      <p className={styles.documents}>Отдельные файлы</p>
    </li>
  );
};

export default DocumHead;
