import React from 'react';
import styles from './MainLogo.module.scss';
import logo from '../../../../images/logo-big--white.svg';
import logoWhite from '../../../../images/logo-text--white.svg';
import classnames from 'classnames';

const MainLogo = (props) => {
  return (
    <a
      href='/'
      className={classnames(
        props.isShowMenu ? [styles.logo, styles.open] : [styles.logo]
      )}
    >
      <img
        src={props.isShowMenu ? [logoWhite] : [logo]}
        alt='logo'
        width={props.isShowMenu ? ['200'] : ['375']}
        height={props.isShowMenu ? ['30'] : ['325']}
      />
    </a>
  );
};

export default MainLogo;
