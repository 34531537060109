import React from 'react';
import styles from './ObjectsContentItem.module.scss';
import stylesDesc from './ObjectItemInfo/ObjectItemInfo.module.scss';
import ObjectItemHead from './ObjectItemHead/ObjectItemHead';
import ObjectItemSlider from './ObjectItemSlider/ObjectItemSlider';
import ObjectItemInfo from './ObjectItemInfo/ObjectItemInfo';
import ButtonBack from '../../../../universal_components/ButtonBack/ButtonBack';

import objectMaskFullImage from '../../../../../images/object_mask_profile.svg';
import { isMobile } from 'react-device-detect';
import FullScreenImage from '../FullScreenImage/FullScreenImage';
import { useState } from 'react';

const ObjectsContentItem = ({ currentObject }) => {
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState();

  const openFullScreenImg = (clickedPhoto) => {
    setCurrentPhoto(clickedPhoto);
    setShowFullScreenImage(true);
  };

  const next = () => {
    if (!currentPhoto) return;
    currentObject.photos.forEach((item, index) => {
      if (item.photo === currentPhoto) {
        if (currentObject.photos[index + 1] === undefined) {
          setCurrentPhoto(currentObject.photos[0].photo);
        } else {
          setCurrentPhoto(currentObject.photos[index + 1].photo);
          return;
        }
      }
    });
  };
  const prev = () => {
    if (!currentPhoto) return;
    currentObject.photos.forEach((item, index) => {
      if (item.photo === currentPhoto) {
        if (currentObject.photos[index - 1] === undefined) {
          setCurrentPhoto(
            currentObject.photos[currentObject.photos.length - 1].photo
          );
        } else {
          setCurrentPhoto(currentObject.photos[index - 1].photo);
          return;
        }
      }
    });
  };

  return !isMobile ? (
    <div className={styles.item}>
      {showFullScreenImage && (
        <FullScreenImage
          imageList={currentObject.photos}
          prev={prev}
          next={next}
          current={currentPhoto}
          close={() => setShowFullScreenImage(false)}
        />
      )}
      <div
        className={styles.head}
        // style={
        //   currentObject.video_link === null
        //     ? { marginBottom: 45 }
        //     : { marginBottom: 56 }
        // }
      >
        <ObjectItemHead currentObject={currentObject} />
      </div>
      <div className={styles.slider}>
        {currentObject.photos.length > 0 ? (
          <ObjectItemSlider
            item={currentObject.photos}
            openFullScreenImg={openFullScreenImg}
          />
        ) : (
          <img
            src={objectMaskFullImage}
            style={{ width: '100%', height: 397 }}
          />
        )}
      </div>
      <div className={styles.info}>
        <ObjectItemInfo currentObject={currentObject} />
      </div>
      {currentObject.description && (
        <div className={stylesDesc.desc}>
          <h3>Описание</h3>
          <p>{currentObject.description}</p>
        </div>
      )}
      {currentObject.documents && currentObject.documents.length > 0 && (
        <div className={stylesDesc.documentsList}>
          <h3>Документы</h3>
          {currentObject.documents.map((item, index) => (
            <a key={index} href={item.document} target='_blank'>
              {item.title}
            </a>
          ))}
        </div>
      )}

      {/* {!isMobile && <div></div>} */}
      <div className={styles.button_back}>
        <ButtonBack />
      </div>
    </div>
  ) : (
    <div className={styles.item}>
      {showFullScreenImage && (
        <FullScreenImage
          imageList={currentObject.photos}
          prev={prev}
          next={next}
          current={currentPhoto}
          close={() => setShowFullScreenImage(false)}
        />
      )}

      <div
        className={styles.head}
        style={
          currentObject.video_link === null
            ? { marginBottom: 84 }
            : { marginBottom: 56 }
        }
      >
        <ObjectItemHead currentObject={currentObject} />
      </div>
      <div className={styles.slider}>
        {currentObject.photos.length > 0 ? (
          <ObjectItemSlider
            item={currentObject.photos}
            openFullScreenImg={openFullScreenImg}
          />
        ) : (
          <img src={objectMaskFullImage} style={{ marginTop: 30 }} />
        )}
      </div>
      <div className={styles.info}>
        <ObjectItemInfo currentObject={currentObject} />
      </div>

      {!isMobile && (
        <div className={styles.button_back}>
          <ButtonBack to={'/objects'} />
        </div>
      )}
    </div>
  );
};

export default ObjectsContentItem;
