import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import WorkersItem from "./WorkersItem/WorkersItem";

import styles from "./Workers.module.scss";

const WorkersPage = () => {
  const [workers, setWorkers] = useState();
  const workersRedux = useSelector((state) => state.workers.workers);

  useEffect(() => {
    if (!workersRedux) return;
    const object = { directors: [], managers: [], others: [] };
    workersRedux.forEach((item) => {
      switch (item.role) {
        case "d":
          object.directors.push(item);
          break;
        case "m":
          object.managers.push(item);
          break;
        case "o":
          object.others.push(item);
          break;
        default:
          return;
      }
    });

    setWorkers(object);
  }, [workersRedux]);

  return (
    <div className={styles.workersBox}>
      <div className={styles.workersWrapper}>
        {workers?.directors && (
          <div>
            {workers.directors.map((item, index) => (
              <WorkersItem
                key={index}
                photo={item.cropped_photo}
                fio={item.fio}
                position={item.position}
                email={item.email}
                phone={item.phone}
              />
            ))}
          </div>
        )}
        {workers?.managers && (
          <div>
            {workers.managers.map((item, index) => (
              <WorkersItem
                key={index}
                photo={item.cropped_photo}
                fio={item.fio}
                position={item.position}
                email={item.email}
                phone={item.phone}
              />
            ))}
          </div>
        )}
        {workers?.others && (
          <div>
            {workers.others.map((item, index) => (
              <WorkersItem
                key={index}
                photo={item.cropped_photo}
                fio={item.fio}
                position={item.position}
                email={item.email}
                phone={item.phone}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkersPage;
