import React, { useEffect } from 'react';

import './App.css';
import './components/App/fonts.scss';
import './components/App/var.scss';
import './components/App/reset.scss';
import './components/App/global.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './css/custom-slider.scss';

import { Route, Routes, useLocation } from 'react-router-dom';

import Promo from './components/pages/Promo/Promo';
import Header from './components/universal_components/header/Header';
import Footer from './components/universal_components/Footer/Footer';
import Objects from './components/pages/Objects/Objects';
import AboutActivities from './components/pages/About/About';
import Partners from './components/pages/Partners/Partners';
import { useDispatch, useSelector } from 'react-redux';
import { getNews } from './requests/getNews';
import Media from './components/pages/News/Media';
import Events from './components/pages/News/Events';
import { getPartners } from './requests/getPartners';
import NewsContent from './components/pages/News/NewsContent/NewsContent';
import { getObjects } from './requests/getObjects';
import Docum from './components/pages/Docum/Docum';
import { getDocumCategories, getDocuments } from './requests/getDocuments';
import NewsRedirect from './components/pages/News/NewsRedirect';
import ObjectInfoPage from './components/pages/Objects/ObjectInfoPage';
import { getPurchases } from './requests/getPurchases';
import { isMobile } from 'react-device-detect';
import PurchasesAgreg from './components/pages/Purchases/PurchasesAgreg';
import PurchasesInfoWrap from './components/pages/Purchases/PurchasesInfoWrap';
import { getProcurements } from './requests/getProcurements';
import PurchasesRedirect from './components/pages/Purchases/PurchasesRedirect';
import PurchasesRegistry from './components/pages/Purchases/PurchasesRegistry';
import { getOrganizations } from './requests/getOrganizations';
import Workers from './components/pages/About/Workers/Workers';
import { getWorkers } from './requests/getWorkers';
import { getLinks } from './requests/getLinks';
import AboutRedirect from './components/pages/About/AboutRedirect';
import VacancyDescription from './components/pages/About/Vacancies/VacancyDescription/VacancyDescription';
import VacanciesWrapper from './components/pages/About/Vacancies/VacanciesWrapper';
import { getVacancies } from './requests/getVacancies';
import SuperVisiory from './components/pages/About/SuperVisiory/SuperVisiory';
import {
  getSuperVisiory,
  getSuperVisioryText,
} from './requests/getSuperVisiory';
import Experts from './components/pages/About/Experts/Experts';
import { getExperts, getExpertsText } from './requests/getExperts';
import RegistryPurchasesRedirect from './components/pages/Purchases/RegistryPurchasesRedirect';
import Contests from './components/pages/Contests/Contests';
import MasterPlan from './components/pages/MasterPlan/MasterPlan';
import CurrentMasterPlan from './components/pages/CurrentMasterPlan/CurrentMasterPlan';
import PersonalData from './components/pages/PersonalData/PersonalData';
import { getMPNews } from './requests/getMPNews';
import { getMPs } from './requests/getMPs';
import AllIdeas from './components/pages/CurrentMasterPlan/MapMP/AllIdeas/AllIdeas';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getMPs());
    dispatch(getMPNews());
    dispatch(getNews());
    dispatch(getPartners());
    dispatch(getObjects());
    dispatch(getDocuments());
    dispatch(getDocumCategories());
    dispatch(getSuperVisiory());
    dispatch(getSuperVisioryText());
    dispatch(getExperts());
    dispatch(getExpertsText());
    dispatch(getPurchases());
    dispatch(getProcurements());
    dispatch(getOrganizations());
    dispatch(getWorkers());
    dispatch(getLinks());
    dispatch(getVacancies());
  }, [dispatch]);

  return (
    <div
      className='App'
      style={
        isMobile && location.pathname === '/' ? { overflow: 'hidden' } : {}
      }
    >
      {!location.pathname.includes('/masterplan') && <Header />}
      <Routes>
        <Route path='/' element={<Promo />} />

        <Route path='/about' element={<AboutRedirect />} />
        <Route path='/about/company' element={<AboutActivities />} />
        <Route path='/about/supervisory' element={<SuperVisiory />} />
        <Route path='/about/workers' element={<Workers />} />
        <Route path='/about/experts' element={<Experts />} />
        <Route path='/about/vacancy' element={<VacanciesWrapper />} />

        <Route path='/objects' element={<Objects />} />
        <Route path='/objects/:id' element={<ObjectInfoPage />} />

        <Route path='/purchases' element={<PurchasesRedirect />} />
        <Route path='/registry' element={<RegistryPurchasesRedirect />} />
        <Route path='/purchases/info' element={<PurchasesInfoWrap />} />
        <Route path='/purchases/agregator/:type' element={<PurchasesAgreg />} />

        <Route path='/registry/:type' element={<PurchasesRegistry />} />

        <Route path='/news' element={<NewsRedirect />} />
        <Route path='/news/media' element={<Media />} />
        <Route path='/news/events' element={<Events />} />
        <Route path='/news/media/:id' element={<NewsContent />} />
        <Route path='/news/events/:id' element={<NewsContent />} />

        <Route path='/contests' element={<Contests />} />

        <Route path='/partners' element={<Partners />} />

        <Route path='/documents' element={<Docum />} />
        <Route path='/privacy' element={<PersonalData />} />
        <Route path='/masterplan' element={<MasterPlan />} />
        <Route path='/masterplan/:id' element={<CurrentMasterPlan />} />
        <Route path='/masterplan/:id/ideas/:idIdea' element={<AllIdeas />} />
      </Routes>
      {!(
        location.pathname === '/' || location.pathname.includes('/masterplan')
      ) && <Footer />}
    </div>
  );
}

export default App;
