import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./Search.module.scss";
import { isMobile } from 'react-device-detect';

const Search = ({ onChange, width }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    onChange(value);
  }, [value])
  return (
    <div className={styles.search}>
      <input className={styles.input} value={value} type="text" placeholder="Название" onChange={(e) => setValue(e.target.value)} />
    </div>
  );
};

export default Search;
