import React, { useEffect, useState } from 'react';
import Burger from '../Burger/Burger';
import styles from './Header.module.scss';
import MainLogo from './mainLogo/MainLogo';
import Nav from './nav/Nav';
import classnames from 'classnames';
import SliderMission from '../Slider-mission/SliderMission';
import PageLogo from '../logo/PageLogo/PageLogo';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const Header = (link, nameLink) => {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isPromo, setIsPromo] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === '/' ||
      (location.pathname.includes('/masterplan') &&
        !location.pathname.includes('/ideas'))
    ) {
      setIsPromo(true);
      return;
    }
    setIsPromo(false);
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleMenu = () => {
    setIsShowMenu(!isShowMenu);
  };

  return (
    <header
      className={classnames(
        isShowMenu ? [styles.header, styles.open] : [styles.header],
        {
          [styles.notFixed]:
            location.pathname.includes('/masterplan') && !isShowMenu,
        }
      )}
      style={
        !isPromo
          ? { backgroundColor: '#ffffff', paddingBottom: 20, marginRight: 20 }
          : {}
      }
    >
      {(isPromo || isShowMenu) &&
        !location.pathname.includes('/masterplan') && (
          <>
            <MainLogo isShowMenu={isShowMenu} />
          </>
        )}
      {/* {!isShowMenu && <PageLogo /> } */}
      <Nav
        isShowMenu={isShowMenu}
        isWhiteText={isPromo}
        toggleMenu={toggleMenu}
      />
      <Burger isShowMenu={isShowMenu} toggleMenu={toggleMenu} />
      {!location.pathname.includes('/masterplan') && (
        <SliderMission location='menu' />
      )}
      {!isShowMenu && isMobile && !isPromo && (
        <div className={styles.after}>
          <PageLogo />
        </div>
      )}
    </header>
  );
};

export default Header;
