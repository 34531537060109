import React, { useEffect, useState } from "react";
import PurchasesInfo from "./PurchasesInfo/PurchasesInfo";
import PurchasesAggregator from "./PurchasesAggregator/PurchasesAggregator";
import styles from "./PurchasesContent.module.scss";
import PurchasesRegistr from "./PurchasesRegistr/PurchasesRegistr";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const PurchasesContent = ({ children }) => {
  

  return (
    <div className={styles.content}>
      {children}
      {/* <PurchasesInfo /> */}
      {/* <PurchasesAggregator data={data} /> */}
      {/* <PurchasesRegistr /> */}
    </div>
  );
};

export default PurchasesContent;
