import React from "react";
import classnames from "classnames";
import styles from "./Burger.module.scss";

const Burger = (props) => {
  return (
    <button
      onClick={props.toggleMenu}
      className={classnames(
        props.isShowMenu ? [styles.burger, styles.open] : [styles.burger]
      )}
    >
      <span />
      <span />
      <span />
      <span />
      <span className="visually-hidden">бургер меню</span>
    </button>
  );
};

export default Burger;
