import React, { useEffect, useState } from "react";
import MainContainerLeftControl from "../../universal_components/MainContainerLeftControl/MainContainerLeftControl";
import styles from "./News.module.scss";
import NewsList from "./NewsContent/NewsList/NewsList";
import { useSelector } from 'react-redux';
import NewsAside from './NewsAside/NewsAside';
import NewsAsideListControl from './NewsAside/NewsAsideListControl/NewsAsideListControl';
import { isMobile } from 'react-device-detect';

import defaultImage from '../../../images/object_mask_profile.svg';


const Media = () => {
  // ширина боковой колонки
  const options = "250px";

  const [news, setNews] = useState([]);
  const newsList = useSelector(state => state.news.media)

  useEffect(() => {
    if (!newsList) return;
    newsList.map(item => {
      if (item.images.length === 0) {
        item.images.push({image: defaultImage, cropped_image: defaultImage})
      }
    })
    setNews(newsList)
  }, [newsList])

  const windowWidth = window.innerWidth;

  return (
    <section className={styles.news}>
      <MainContainerLeftControl widthAside={options} data={<NewsAsideListControl />}>
      {isMobile && <h2 className="title"></h2>}
        {(isMobile) && <NewsAsideListControl />}
        {news && <NewsList news={news} />}
      </MainContainerLeftControl>
    </section>
  );
};

export default Media;
