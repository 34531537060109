import classnames from "classnames";
import React, { useEffect } from "react";
import styles from "./ObjectAsideItem.module.scss";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import objectMaskImage from "../../../../../images/object_mask.svg";
import { useRef } from "react";

const ObjectAsideItem = (props) => {
  const params = useParams();

  const refItem = useRef();

  useEffect(() => {
    if (!props.activeMarker) return;
    let elem = refItem.current;

    let topPos = elem.offsetTop;

    if (
      props.activeMarker &&
      props.activeMarker[0] == props.loc1 &&
      props.activeMarker[1] == props.loc2
    ) {
      scrollTo(props.crolledBlock.current, topPos - 30, 600);
    }

    Math.easeInOutQuad = function (t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    function scrollTo(element, to, duration) {
      let start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

      let animateScroll = async function () {
        currentTime += increment;
        let val = await Math.easeInOutQuad(
          currentTime,
          start,
          change,
          duration
        );
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    }

    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    Math.easeInOutQuad = function (t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };
  }, [props.activeMarker]);

  return (
    <li
      className={styles.item}
      onMouseEnter={
        !params.id ? () => props.hoverHandler(props.loc1, props.loc2) : null
      }
    >
      <Link
        to={"/objects/" + props.id}
        ref={refItem}
        className={classnames(
          props.toggle ||
            (props.activeMarker &&
              props.activeMarker[0] == props.loc1 &&
              props.activeMarker[1] == props.loc2)
            ? [styles.button, styles.pressed]
            : [styles.button]
        )}
      >
        <p className={styles.title}>{props.title}</p>
        <p className={styles.street}>{props.street}</p>
        <p className={styles.status}>{props.status}</p>

        {props.image.length > 0 ? (
          <img
            className={styles.image}
            src={props.image[0].thumbnails.low}
            alt=""
          />
        ) : (
          <img className={styles.image} src={objectMaskImage} alt="" />
        )}
      </Link>
    </li>
  );
};

export default ObjectAsideItem;
