import React from 'react';
import styles from './NewsAsideListControl.module.scss';
import { NavLink, useLocation } from 'react-router-dom';
import NewsAsideItemControl from '../NewsAsideItemControl/NewsAsideItemControl';

const NewsAsideListControl = ({ type, prev, next, currentId }) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.link}>
      <NavLink
        to={'/news/events'}
        className={({ isActive }) =>
          isActive
            ? `${styles.pressed} ${styles.inner_link}`
            : `${styles.inner_link}`
        }
      >
        События
      </NavLink>
      {pathname && pathname.includes('events') && (
        <NewsAsideItemControl
          type={type}
          prev={prev}
          next={next}
          currentId={currentId}
        />
      )}

      {pathname && pathname.includes('media') && (
        <NewsAsideItemControl
          type={type}
          prev={prev}
          next={next}
          currentId={currentId}
        />
      )}
      <NavLink
        to={'/news/media'}
        className={({ isActive }) =>
          isActive
            ? `${styles.pressed} ${styles.inner_link}`
            : `${styles.inner_link}`
        }
      >
        Медиа
      </NavLink>
    </div>
  );
};

export default NewsAsideListControl;
