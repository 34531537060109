import React, { useRef } from "react";
import styles from "./MainContainerLeftControl.module.scss";
import Aside from "../Aside/Aside";
import Search from "../Search/Search";

import { ReactComponent as DecorImageAbout } from "../../../images/decor_about.svg";
import { useLocation } from "react-router-dom";

const MainContainerLeftControl = ({ children, widthAside, data }) => {
  const { pathname } = useLocation();
  return (
    <>
      <div className={styles.leftControl} id='container'>
        <div className={styles.aside}>
          <Aside widthAside={widthAside} data={data} />
          {(pathname === "/about/company" || pathname === '/about/workers') && (
            <div className={styles.decorAbout}>
              <DecorImageAbout />
            </div>
          )}
        </div>

        <div className={styles.children}>{children}</div>
      </div>
    </>
  );
};

export default MainContainerLeftControl;
