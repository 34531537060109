import React from 'react';
import DG from '2gis-maps';
import styles from './ObjectMap.module.scss';
import logo from '../../../../../../images/logo-blue.svg';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

import './ObjectMap.scss';

import iconMarker from '../../../../../../images/map_marker.svg';
import { Link } from 'react-router-dom';

const ObjectMap = ({ getActiveMarker, filteredArray }) => {
  const [objects, setObjects] = useState();
  const objectsRedux = useSelector((state) => state.objects.objects);

  useEffect(() => {
    if (!objectsRedux) return;
    setObjects(objectsRedux);
  }, [objectsRedux]);

  useEffect(() => {
    if (!objects) return;
    const locArray = [];
    if (!filteredArray) {
      objects.map((item) =>
        locArray.push({
          id: item.id,
          name: item.name,
          lat: item.marker_latitude,
          lon: item.marker_longitude,
          street: item.address,
        })
      );
    } else {
      filteredArray.map((item) =>
        locArray.push({
          id: item.id,
          name: item.name,
          lat: item.marker_latitude,
          lon: item.marker_longitude,
          street: item.address,
        })
      );
    }

    let map = DG.map('map', {
      center: [56.008758, 92.870314],
      zoom: 6,
    });

    let icon = DG.icon({
      iconUrl: iconMarker,
      iconSize: [30, 30],
    });

    locArray.map((item) => {
      DG.marker([item.lat, item.lon], { icon: icon })
        .on('click', (e) => {
          getActiveMarker(e.latlng.lat, e.latlng.lng);
        })
        .addTo(map)
        .bindPopup(
          '<a href="/objects/' +
            item.id +
            '"><p>' +
            item.name +
            '</p>' +
            '<span>' +
            item.street +
            '</span></a>'
        );
    });
    return () => map.remove();
  }, [objects, filteredArray]);

  return (
    <div className={styles.map}>
      <div
        id='map'
        style={{ width: '100%', height: 414, border: '2px solid #00405b' }}
      ></div>
      <img
        className={styles.decorImg}
        src={logo}
        alt=''
        width='138'
        height='138'
      />
    </div>
  );
};

export default ObjectMap;
