import React from 'react';
import styles from './Footer.module.scss';
import iconVk from '../../../images/icon-vk.svg';
import iconTg from '../../../images/icon-tg.svg';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonBack from '../ButtonBack/ButtonBack';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const Footer = () => {
  const [links, setLinks] = useState();
  const linksRedux = useSelector((state) => state.links.links);

  useEffect(() => {
    if (linksRedux.length === 0) return;
    setLinks(linksRedux);
  }, [linksRedux]);

  const location = useLocation();
  const isPromo = location.pathname === '/' ? true : false;
  return (
    <footer
      className={styles.footer}
      style={location.pathname === '/purchases/info' ? { marginTop: -68 } : {}}
    >
      {isMobile && !isPromo && <ButtonBack />}

      <h2>АНО РГП «Институт города»</h2>

      <address className={styles.address}>
        <a
          href='https://yandex.ru/maps/-/CCU9U8dXsA'
          target='_blank'
          rel='noopener noreferrer'
        >
          Красноярск, ул. Обороны, д. 3
        </a>
      </address>

      <a className={styles.tel} href='tel:+89029690339'>
        +7 (902) 969-03-39
      </a>
      <a
        className={styles.mail}
        target='_blank'
        rel='noopener noreferrer'
        href='mailto:info@ingorod24.ru'
      >
        info@ingorod24.ru
      </a>
      <div className={styles.links}>
        {links && links.tg !== '' && (
          <a href={links.tg} target='_blank' className={styles.vk}>
            <img src={iconTg} alt='' width='32' height='32' />
          </a>
        )}
        {links && links.vk !== '' && (
          <a href={links.vk} target='_blank' className={styles.vk}>
            <img src={iconVk} alt='' width='32' height='32' />
          </a>
        )}
      </div>
    </footer>
  );
};

export default Footer;
