import React, { useEffect } from 'react';
import styles from './ItemMenu.module.scss';
import classnames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import AnchorLink from 'react-anchor-link-smooth-scroll';

function ItemMenu(props) {
  const location = useLocation();

  return (
    <li
      className={classnames(
        props.isShowMenu ? [styles.item, styles.open] : [styles.item]
      )}
      style={
        props.isWhiteText
          ? { borderLeftColor: 'white' }
          : { borderLeftColor: '#00405b' }
      }
    >
      {' '}
      {props.link.includes('#') && !props.link.includes('/') ? (
        <AnchorLink
          style={
            props.isWhiteText || props.isShowMenu
              ? { color: 'white' }
              : { color: '#00405b' }
          }
          // className={({ isActive }) => (isActive ? styles.pressed : '')}
          href={props.link}
        >
          {props.name}
        </AnchorLink>
      ) : (
        <NavLink
          to={props.link}
          onClick={window.innerWidth <= 1000 ? props.toggleMenu : null}
          className={({ isActive }) =>
            isActive && !props.link.includes('/masterplan#')
              ? !location.pathname.includes('/ideas')
                ? styles.pressed
                : ''
              : ''
          }
          style={
            props.isWhiteText || props.isShowMenu
              ? { color: 'white' }
              : { color: '#00405b' }
          }
          end={props.link === '/' ? true : false}
        >
          {props.name}
        </NavLink>
      )}
    </li>
  );
}

export default ItemMenu;
