import React from "react";
import styles from "./Contests.module.scss";

const ContestsItem = ({ item }) => {
  const dateFormatter = (string) => {
    return string.split("-").reverse().join(".");
  };
  const date = Date.now();

  return (
    <a
      href={"https://konkurs.ingorod24.ru/" + item.slug}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.item}>
        <img src={item.cropped_photo} alt={item.title} />
        <div className={styles.text}>
          <div className={styles.head}>
            <p>{item.title}</p>
            <span
              className={
                date > Date.parse(item.finish_date) ? "" : styles.currentContest
              }
            >
              {dateFormatter(item.start_date)} -{" "}
              {dateFormatter(item.finish_date)}
            </span>
          </div>
          <div className={styles.description}>{item.description}</div>
        </div>
      </div>
    </a>
  );
};

export default ContestsItem;
