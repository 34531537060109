import React from "react";
import styles from "./NewsAsideItemControl.module.scss";
import { Link } from "react-router-dom";

const NewsAsideItemControl = ({ prev, next, type, currentId }) => {
  return currentId ? (
    <div className={styles.links_wrap}>
      {currentId && next && currentId !== next.id && (
        <Link to={"/news/" + type + "/" + next.id} className={styles.next}>
          <div></div> Следующее
        </Link>
      )}
      {currentId && prev && currentId !== prev.id && prev && (
        <Link to={"/news/" + type + "/" + prev.id} className={styles.prev}>
          <div></div>Предыдущее
        </Link>
      )}

      {/* {next && <Link to={"/news/" + type + "/" + next.id}>{next.title}</Link>} */}
    </div>
  ) : null;
};

export default NewsAsideItemControl;
