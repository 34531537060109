import React from "react";
import AsideFixedBox from "../../universal_components/Aside/AsideFixedBox/AsideFixedBox";
import MainContainerLeftControl from "../../universal_components/MainContainerLeftControl/MainContainerLeftControl";
import styles from "./About.module.scss";
import AboutCompany from "./AboutCompany/AboutCompany";
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import History from './AboutCompany/History/History';
import Activities from './AboutCompany/Activities/Activities';
import MapAbout from './AboutCompany/Map/MapAbout';
import VacancyDescription from './Vacancies/VacancyDescription/VacancyDescription';
import { isMobile } from 'react-device-detect';
import ButtonBack from "../../universal_components/ButtonBack/ButtonBack";

const AboutActivities = () => {
  // ширина боковой колонки
  const options = "250px";
  const windowWidth = window.innerWidth;

  return (
    <section className={styles.about}>
      <MainContainerLeftControl widthAside={options} data={<AsideFixedBox />}>
        {(isMobile ) && (
          <>
            <h2 className="title">О НАС</h2>
            <div style={{marginBottom: 27}}>
              <AsideFixedBox />
            </div>
          </>
        )}
        <History />
        <Activities /> 
        <MapAbout />

      </MainContainerLeftControl>
    </section>
  );
};

export default AboutActivities;
