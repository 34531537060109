import { getLinksAction } from '../redux/Reducers/linksReducer';
import { BASE_URL } from './BASE_URL';

export const getLinks = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL + 'index/links/');
        const data = await response.json();

        if (response.status === 200) {
          dispatch(getLinksAction(data))
        }
    }
}