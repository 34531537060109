import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PurchasesRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/purchases/info');
  }, [])
  return (
    <div></div>
  )
}

export default PurchasesRedirect;