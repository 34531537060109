import { animateScroll as scroll } from 'react-scroll';

import { ReactComponent as Logo } from '../../../../images/logo-big--white.svg';
import { ReactComponent as Email } from '../../../../images/MasterPlan/email.svg';
import { ReactComponent as Phone } from '../../../../images/MasterPlan/phone.svg';
import { ReactComponent as Location } from '../../../../images/MasterPlan/location.svg';
import { ReactComponent as Arrow } from '../../../../images/MasterPlan/arrow.svg';

import styles from './Footer.module.scss';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div className={styles.footer}>
      <div className={styles.wrapperLogo}>
        <Logo onClick={() => navigate('/')} className={styles.logo} />
      </div>
      <div className={styles.wrapperContacts}>
        <div className={styles.contacts}>
          <p className={styles.title}>Адрес:</p>
          <div className={styles.text}>
            <Location />
            <p>г. Красноярск, ул. Обороны, д.3</p>
          </div>
          <p className={styles.title}>Контакты:</p>
          <div className={styles.text}>
            <Phone />
            <a href='tel:+79029690339">'>+7 (902) 969-03-39</a>
          </div>
          <div className={styles.text}>
            <Email />
            <a href='mailto:masterplan@ingorod24.ru'>
            masterplan@ingorod24.ru
            </a>
          </div>
        </div>
        <Arrow onClick={scrollToTop} className={styles.arrow} />
      </div>
    </div>
  );
};

export default Footer;
