import React from "react";
import styles from "./Aside.module.scss";
import Search from '../Search/Search';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import PageLogo from '../logo/PageLogo/PageLogo';

const Aside = ({ data, widthAside, pageName, onChange }) => {
  const location = useLocation();
  return (
    <div className={styles.aside} style={!isMobile ? { width: widthAside } : {}}>
      {location.pathname !== '/documents' && <PageLogo />}
      <div className={styles.title}>
        <h1>{pageName}</h1>
      </div>
      {(isMobile && location.pathname === '/documents') && <Search onChange={onChange} />}
      {data}
    </div>
  );
};

export default Aside;
