import React from "react";
import styles from "./PartnersItemMobile.module.scss";

const PartnersItemMobile = ({ item, id }) => {
  return (
    <div className={styles.item}>
        <img src={item.logo} alt="" id={id} />
    </div>
  );
};

export default PartnersItemMobile;
