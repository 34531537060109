const initialState = {
    organizations: [],
    design_accreditation_direction: false,
    local_accreditation_direction: false,
    contractor_accreditation_direction: false,
}

const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';

export const organizationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORGANIZATIONS:
            const designDireactions = []
            const localDireactions = []
            const contractorDireactions = []

            action.payload && action.payload.map(item => {
                if (item.accreditation_direction.length > 0) {
                    if (item.category === 'local') {
                        item.accreditation_direction.forEach(element => {
                            let isHas = false
                            localDireactions.forEach(subElement => {
                                if (subElement.title === element.title) {
                                    isHas = true;
                                    return;
                                }
                            })
                            if (!isHas) {
                                localDireactions.push(element)
                            }
                        });
                    } else if (item.category === 'design') {
                        item.accreditation_direction.forEach(element => {
                            let isHas = false
                            designDireactions.forEach(subElement => {
                                if (subElement.title === element.title) {
                                    isHas = true;
                                    return;
                                }
                            })
                            if (!isHas) {
                                designDireactions.push(element)
                            }
                        });
                    } else if (item.category === 'contractor') {
                        item.accreditation_direction.forEach(element => {
                            let isHas = false
                            contractorDireactions.forEach(subElement => {
                                if (subElement.title === element.title) {
                                    isHas = true;
                                    return;
                                }
                            })
                            if (!isHas) {
                                contractorDireactions.push(element)
                            }
                        });
                    }
                }
            })


            return {...state,
                organizations: action.payload,
                design_accreditation_direction: designDireactions,
                local_accreditation_direction: localDireactions,
                contractor_accreditation_direction: contractorDireactions,
            }
        default:
            return state;
    }
}

export const getOrganizationsAction = (payload) => ({type: GET_ORGANIZATIONS, payload})