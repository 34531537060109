import React from 'react';
import styles from './ObjectsContent.module.scss';
import ObjectsContentControl from './ObjectsContentControl/ObjectsContentControl';
import ObjectsContentItem from './ObjectsContentItem/ObjectsContentItem';
import { isMobile } from 'react-device-detect';
import ObjectsAside from '../ObjectsAside/ObjectsAside';
import { useSelector } from 'react-redux';

const ObjectsContent = ({
  filter,
  data,
  getActiveMarker,
  activeMarker,
  getFiltereObjects,
  filteredArray,
}) => {
  return (
    <div className={styles.content}>
      <ObjectsContentControl
        filter={filter}
        getActiveMarker={getActiveMarker}
        filteredArray={filteredArray}
      />
      {isMobile && (
        <ObjectsAside
          data={data}
          filter={filter}
          activeMarker={activeMarker}
          getFiltereObjects={getFiltereObjects}
        />
      )}
      {/* <ObjectsContentItem data={data} /> */}
    </div>
  );
};

export default ObjectsContent;
