import { getProcurementsAction } from "../redux/Reducers/procurementsReducer";
import { getPurchasesAction } from "../redux/Reducers/purchasesReducer";
import { BASE_URL } from "./BASE_URL";

export const getProcurements = () => {
  return async (dispatch) => {
    const response = await fetch(
      BASE_URL + "purchases/procurements/?limit=550"
    );
    const data = await response.json();

    if (response.status === 200) {
      dispatch(getProcurementsAction(data.results));
    }
  };
};
