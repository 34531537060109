import classnames from 'classnames';
import React from 'react';
import styles from './ObjectControlTerritory.module.scss';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

import arrow from '../../../../../../../images/grey-arrow.svg';
import { useRef } from 'react';

const ObjectControlTerritory = ({ modal, onChangeHandler, filtersArray }) => {
  const filter = useSelector((state) => state.object_filters.filters);

  const [territory, setTerritory] = useState();
  const [showTerritoryList, setShowTerritoryList] = useState(false);
  const [searchValue, setSearchValue] = useState(filter.territory);
  const [filteredList, setFilteredList] = useState();
  const territoryRedux = useSelector((state) => state.objects.territory);

  const rootEl = useRef();

  useEffect(() => {
    if (showTerritoryList) {
      const onClick = (e) => {
        if (!rootEl.current.contains(e.target)) {
          setShowTerritoryList(false);
        }
      };
      document.addEventListener('click', onClick);
      return () => document.removeEventListener('click', onClick);
    }
  }, [showTerritoryList]);

  useEffect(() => {
    if (!territory) return;
    const array = territory.filter((item) =>
      item.toLowerCase().includes(searchValue.toLowerCase())
    );
    array.sort();
    setFilteredList(array);
    onChangeHandler(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (!territoryRedux) return;
    const array = [];
    territoryRedux.map((item) => {
      if (array.includes(item) === false) {
        array.push(item);
      }
    });
    array.sort();
    setTerritory(array);
  }, [territoryRedux]);

  return (
    <fieldset
      className={classnames(
        modal ? [styles.territory, styles.modal] : [styles.territory]
      )}
    >
      <legend className='visually-hidden'>Территория</legend>
      <h3>Территория</h3>
      <div className={styles.selectWrapper} ref={rootEl}>
        <input
          value={searchValue}
          onChange={(e) => {
            if (!showTerritoryList) {
              setShowTerritoryList(true);
            }
            setSearchValue(e.target.value);
          }}
          type='text'
          placeholder='Введите название'
          onFocus={() => setShowTerritoryList(true)}
        />
        <img
          src={arrow}
          alt=''
          onClick={() => setShowTerritoryList(!showTerritoryList)}
          style={!showTerritoryList ? {} : { transform: 'rotate(180deg)' }}
          className={styles.decorArrow}
        />
        {showTerritoryList && (
          <ul>
            <li
              onClick={() => {
                setSearchValue('');
                setShowTerritoryList(false);
              }}
            >
              Все территории
            </li>
            {searchValue === ''
              ? territory &&
                territory.map((item, index) => (
                  <li
                    onClick={() => {
                      setSearchValue(item);
                      setShowTerritoryList(false);
                    }}
                    key={index}
                  >
                    {item}
                  </li>
                ))
              : filteredList &&
                filteredList.map((item, index) => (
                  <li
                    onClick={() => {
                      setSearchValue(item);
                      setShowTerritoryList(false);
                    }}
                    key={index}
                  >
                    {item}
                  </li>
                ))}
          </ul>
        )}
      </div>
      {/* <label>
        <input className="visually-hidden" type="checkbox" checked={filtersArray.territory.includes('municipality')} onChange={(e) => onChangeHandler('municipality')} />
        <span className={styles.mark}></span>
        <span className={styles.name}>Муниципальный район</span>
      </label>

      <label>
        <input className="visually-hidden" type="checkbox" checked={filtersArray.territory.includes('locality')} onChange={(e) => onChangeHandler("locality")} />
        <span className={styles.mark}></span>
        <span className={styles.name}>Населенный пункт</span>
      </label> */}
    </fieldset>
  );
};

export default ObjectControlTerritory;

//
