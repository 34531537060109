import React from 'react';
import styles from './ObjectItemSlider.module.scss';

const DeskctopImageList = ({ item, open }) => {
  return (
    <div className={styles.imageWrappContainer}>
      <div className={styles.desktopImageWrap}>
        {item &&
          item.map((point, index) => (
            <div
              key={index}
              className={styles.img}
              style={{
                backgroundImage: 'url(' + point.thumbnails.middle + ')',
              }}
              onClick={() => open(point.photo)}
            ></div>
          ))}
      </div>
      <div className={styles.decor}></div>
    </div>
  );
};

export default DeskctopImageList;
