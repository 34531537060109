const initialState = {
  ideas: null,
};

const GET_IDEAS = 'GET_IDEAS';

export const MPIdeasReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IDEAS:
      return { ...state, ideas: action.payload };
    default:
      return state;
  }
};

export const getMPIdeasAction = (payload) => ({ type: GET_IDEAS, payload });
