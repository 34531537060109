import React from "react";
import { formatDate } from "../../../../../../functions/formatDate";
import styles from "./NewsItemContentHead.module.scss";

const NewsItemContentHead = ({ title, date, videoLink }) => {
  return (
    <div className={styles.head}>
      <h2 className={styles.title}>{title}</h2>
      <div>
        <p className={styles.date}>{formatDate(date)}</p>
        {videoLink ? (
          <a className={styles.video} href={videoLink.link} target="_blank">
            Смотреть видео
          </a>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default NewsItemContentHead;
