import React, { useState } from 'react';
import MainContainerLeftControl from '../../universal_components/MainContainerLeftControl/MainContainerLeftControl';
import styles from './Objects.module.scss';
// import image from '../../../images/test-town_6.jpg';
import ObjectsAside from './ObjectsAside/ObjectsAside';
import ObjectsContent from './ObjectsContent/ObjectsContent';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { getFiltersAction } from '../../../redux/Reducers/objectFiltersReducer';

const Objects = () => {
  // ширина боковой колонки
  const options = '383px';
  const [mockArray, setMockArray] = useState([]);
  const [filteredArray, setFilteredArray] = useState();
  const [activeMarker, setActiveMarker] = useState();
  const [hoveredLocation, setHoveredLocation] = useState({ loc1: 0, loc2: 0 });
  // const [filters, setFilters] = useState();
  const dispatch = useDispatch();
  const objects = useSelector((state) => state.objects.objects);
  const filter = useSelector((state) => state.object_filters.filters);

  useEffect(() => {
    if (filter.programm.length || filter.territory || filter.years.length) {
      dispatch(getFiltersAction(JSON.parse(sessionStorage.getItem('filter'))));
    }
  }, []);

  const hoverOnObjectAsideItem = (loc1, loc2) => {
    setHoveredLocation({ loc1: loc1, loc2: loc2 });
  };

  useEffect(() => {
    setMockArray(objects);
  }, [objects]);

  useEffect(() => {
    setActiveMarker();
  }, [filter]);

  const getFiltereObjects = (array, isFiltered) => {
    if (isFiltered) setFilteredArray(array);
    if (!isFiltered) setFilteredArray();
  };

  const getActiveMarker = (lat, lon) => {
    setActiveMarker([lat, lon]);
  };
  return (
    mockArray && (
      <div className={styles.objects}>
        <MainContainerLeftControl
          widthAside={options}
          data={
            <ObjectsAside
              activeMarker={activeMarker ? activeMarker : null}
              filter={filter}
              hoverHandler={hoverOnObjectAsideItem}
              data={objects}
              getFiltereObjects={getFiltereObjects}
            />
          }
        >
          {isMobile && (
            <>
              <h2
                className='title'
                style={{ marginBottom: -20, fontSize: 21, fontWeight: 600 }}
              >
                ОБЪЕКТЫ
              </h2>
              {/* <div style={{ marginBottom: 27 }}>
              <AsideFixedBox vacancies={vacancies ? vacancies : []} />
            </div> */}
            </>
          )}
          <ObjectsContent
            // getFilters={setFilters}
            filteredArray={filteredArray}
            getFiltereObjects={getFiltereObjects}
            filter={filter}
            activeMarker={activeMarker ? activeMarker : null}
            data={mockArray}
            getActiveMarker={getActiveMarker}
          />
        </MainContainerLeftControl>
      </div>
    )
  );
};

export default Objects;
