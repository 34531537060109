import { useEffect, useState } from 'react';
import styles from './Stage.module.scss';
import classNames from 'classnames';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { formatDate } from '../../../../functions/formatDate';

const Stage = ({ stages }) => {
  const [activeStageNum, setActiveStageNum] = useState(1);
  const [activeStage, setActiveStage] = useState();

  const width = useWindowWidth();

  useEffect(() => {
    setActiveStage(stages.find((stage) => stage.number == activeStageNum));
  }, [activeStageNum]);

  if (!stages) return false;

  return (
    <div id='stages' className={styles.stageWrapper}>
      <p className={styles.title}>ЭТАПЫ</p>
      <div className={styles.stages}>
        {stages.map((stage, id) => (
          <div
            key={id}
            onClick={() => setActiveStageNum(stage.number)}
            className={styles.numberWrapper}
          >
            <div key={stage.number} className={styles.stage}>
              <div
                className={classNames(styles.num, {
                  [styles.numActive]: activeStageNum == stage.number,
                })}
              >
                {stage.number}
              </div>
              <p className={styles.start}>{formatDate(stage.start_date)}</p>
              <p className={styles.end}>{formatDate(stage.end_date)}</p>
            </div>
            <p className={styles.stageTitle}>{stage.title}</p>
          </div>
        ))}
      </div>
      {activeStage && (
        <div className={styles.desc}>
          <div className={styles.startDate}>
            <p>{formatDate(activeStage.substage[0].start_date)}</p>
          </div>
          {activeStage.substage.map((stage, index) =>
            index % 2 === 0 ? (
              <div key={index} className={styles.item}>
                <p className={styles.itemDate}>
                  {formatDate(stage.start_date)} - {formatDate(stage.end_date)}
                </p>
                <hr className={styles.line} />
                <p className={styles.itemDesc}>{stage.text}</p>
              </div>
            ) : (
              <div key={index} className={styles.itemRight}>
                <p className={styles.itemDateRight}>
                  {formatDate(stage.start_date)} - {formatDate(stage.end_date)}
                </p>
                <hr className={styles.line} />
                <p className={styles.itemDescRight}>{stage.text}</p>
              </div>
            )
          )}
          <div
            className={
              activeStage.substage.length % 2 == 0
                ? styles.endDate
                : styles.endDateRight
            }
          >
            <p>
              {formatDate(
                activeStage.substage[activeStage.substage.length - 1].end_date
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Stage;
