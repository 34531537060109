import React from 'react';

import styles from './Experts.module.scss';
import MainContainerLeftControl from '../../../universal_components/MainContainerLeftControl/MainContainerLeftControl';
import AsideFixedBox from '../../../universal_components/Aside/AsideFixedBox/AsideFixedBox';
import { isMobile } from 'react-device-detect';
import ExpertsPage from './ExpertsPage';

const Experts = () => {
    const options = "250px";
    const windowWidth = window.innerWidth;
  
    return (
      <section className={styles.about}>
        <MainContainerLeftControl widthAside={options} data={<AsideFixedBox />}>
          {(isMobile ) && (
            <>
              <h2 className="title" style={{marginBottom: 20, fontSize: 21, fontWeight: 600}}>О НАС</h2>
              <div style={{marginBottom: 27}}>
                <AsideFixedBox />
              </div>
            </>
          )}
          <ExpertsPage />
        </MainContainerLeftControl>
      </section>
    )
};

export default Experts;