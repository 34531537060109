import React from "react";
import styles from "./ObjectItemSlider.module.scss";
import SliderObjects from "../../../../../universal_components/SliderObjects/SliderObjects";
import { isMobile } from "react-device-detect";
import DeskctopImageList from "./DeskctopImageList";

const ObjectItemSlider = ({ item, video_link, openFullScreenImg }) => {
  return (
    <div className={styles.slider}>
      {isMobile ? (
        // <SliderObjects item={item} openFullScreenImg={openFullScreenImg} />
        <DeskctopImageList item={item} open={openFullScreenImg} />
        ) : (
        <DeskctopImageList item={item} open={openFullScreenImg} />
      )}
    </div>
  );
};

export default ObjectItemSlider;
