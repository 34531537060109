import { ReactComponent as Logo } from '../../../images/logo-big--white.svg';
import Partner from '../../../images/MasterPlan/nikel.jpg';
import Header from '../../universal_components/header/Header';
import Footer from '../MasterPlan/Footer/Footer';
import { animateScroll as scroll } from 'react-scroll';

import styles from './CurrentMasterPlan.module.scss';
import Stage from './Stage/Stage';
import Docs from './Docs/Chapter';
import ButtonBack from '../../universal_components/ButtonBack/ButtonBack';
import Results from './Results/Results';
import MapMP from './MapMP/MapMP';
import { getMP } from '../../../requests/getMP';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Photos from './Photos/Photos';
import { getMPIdea } from '../../../requests/getMPIdea';
import { useDispatch, useSelector } from 'react-redux';

const CurrentMasterPlan = () => {
  const { id } = useParams();
  const [MP, setMP] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const MPIdeasRedux = useSelector((state) => state.MPIdeas.ideas);
  const MPs = useSelector((state) => state.MPs.MPs);

  console.log('text -> ', MP?.documentation)
  useEffect(() => {
    getMP(id).then((data) => setMP(data));
    dispatch(getMPIdea(id));
    scroll.scrollToTop({ duration: 0 });
  }, [id, dispatch]);

  if (!MP) return false;

  return (
    <div>
      <div className={styles.header}>
        <div
          style={{
            backgroundImage: `url("${MP.main_image}")`,
            filter: 'brightness(50%)',
          }}
          className={styles.backgroundImage}
        ></div>
        <Header />
        <div className={styles.text}>
          <Logo onClick={() => navigate('/')} className={styles.logo} />
          <div className={styles.textWrapper}>
            <div className={styles.title}>
              <h1>{MP.title}</h1>
              <a
                href={'https://forms.yandex.ru/u/65ade30e84227c9b25d622b9/'}
                target='_blank'
              >
                {'Отправить идею'}
                {/* text */}
              </a>
            </div>
            <p dangerouslySetInnerHTML={{ __html: MP.text_1 }}></p>
          </div>
          <div className={styles.text2}>
            <p dangerouslySetInnerHTML={{ __html: MP.text_2 }}></p>
          </div>
        </div>
      </div>
      <Stage stages={MP.stage} />
      {MP?.documentation?.chapter && <Docs docs={MP.documentation} />}
      {/* <Results /> */}
      <Photos photos={MP.gallery} />
      <MapMP ideas={MPIdeasRedux} id={id} />
      {MP.participants.length > 0 && (
        <div className={styles.partners}>
          <img className={styles.partner} src={Partner} />
          <img className={styles.partner} src={Partner} />
          <img className={styles.partner} src={Partner} />
          <img className={styles.partner} src={Partner} />
        </div>
      )}
      <div className={styles.backButton}>
        <ButtonBack />
        <ButtonBack
          to={`/masterplan/${MPs.length == id ? 1 : +id + 1}`}
          text='Следующий мастер-план'
        />
      </div>

      <Footer />
    </div>
  );
};

export default CurrentMasterPlan;
