import React, { useEffect } from "react";
import MainContainerLeftControl from "../../universal_components/MainContainerLeftControl/MainContainerLeftControl";
import styles from "./PartnersContent/PartnersContent.module.scss";

import { useState } from "react";
import PartnersAside from "./PartnersAside/PartnersAside";
import PartnersItem from "./PartnersContent/PartnersItem/PartnersItem";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import SliderPartners from "../../universal_components/SliderPartners/SliderPartners";

const Partners = () => {
  // ширина боковой колонки
  const options = "350px";
  const [activePartnerID, setActivePartnerID] = useState();
  const [activePartner, setActivePartner] = useState();
  const [partnersMockArray, setPartnersMockArray] = useState();

  const defaultActivePartner = {
    id: 0,
    image: "",
    organization: "",
    name: "",
    tel: "",
    email: "",
    link: "",
  };

  const partners = useSelector((state) => state.partners.partners);

  const showNewPartner = (id) => {
    setActivePartnerID(Number(id));
  };

  useEffect(() => {
    if (!partners) return;
    setPartnersMockArray(partners);
  }, [partners]);

  useEffect(() => {
    if (!partnersMockArray) return;
    const choosenElement = partnersMockArray.filter(
      (item) => item.id === activePartnerID
    )[0];
    setActivePartner(choosenElement);
  }, [activePartnerID]);

  return (
    <div className={styles.partners}>
      <MainContainerLeftControl
        data={
          activePartner ? (
            <PartnersAside item={activePartner} />
          ) : (
            <PartnersAside item={defaultActivePartner} />
          )
        }
        widthAside={options}
      >
        {!isMobile ? (
          <div className={styles.content}>
            <ul>
              {partnersMockArray &&
                partnersMockArray.map((item, index) => (
                  <PartnersItem
                    key={index}
                    onClick={showNewPartner}
                    item={item}
                    isActive={
                      activePartner && activePartner.id === item.id && true
                    }
                  />
                ))}
            </ul>
          </div>
        ) : (
          <div>
            <h3 className={styles.pageTitle}>ПАРТНЕРЫ</h3>
            <SliderPartners array={partnersMockArray && partnersMockArray} showNewPartner={showNewPartner} activePartner={activePartner} />
            <PartnersAside item={activePartner ? activePartner : defaultActivePartner} />
          </div>
        )}
      </MainContainerLeftControl>
    </div>
  );
};

export default Partners;
