import { getNewsAction } from '../redux/Reducers/MPnewsReducer';
import { BASE_URL } from './BASE_URL';

export const getMPNews = () => {
  return async (dispatch) => {
    const response = await fetch(
      BASE_URL + 'master-plan/news-for-master-plan/'
    );
    const data = await response.json();

    if (response.status === 200) {
      dispatch(getNewsAction(data));
    }
  };
};
