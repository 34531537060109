const initialState = {
    vacancies: [],
    activeVacancy: {}
}

const GET_VACANCIES = 'GET_VACANCIES';
const SET_ACTIVE_VACANCY = 'SET_ACTIVE_VACANCY'

export const vacancyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VACANCIES:
            return {...state,
                vacancies: action.payload,
                activeVacancy: action.payload[0]
            }
        case SET_ACTIVE_VACANCY: 
            const vacancy = state.vacancies.filter(item => item.id === action.payload)
            return {...state,
                activeVacancy: vacancy[0]
            }
        default:
            return state;
    }
}

export const getVacanciesAction = (payload) => ({type: GET_VACANCIES, payload})
export const setActiveVacancyAction = (payload) => ({type: SET_ACTIVE_VACANCY, payload})