import React, { useState } from 'react';
import SliderNews from '../../../../universal_components/SliderNews/SliderNews';
import styles from './NewsItemContent.module.scss';
import NewsItemContentFilling from './NewsItemContentFilling/NewsItemContentFilling';
import NewsItemContentHead from './NewsItemContentHead/NewsItemContentHead';
import ButtonBack from '../../../../universal_components/ButtonBack/ButtonBack';
import { useLocation, useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import NewsAsideItemControl from '../../NewsAside/NewsAsideItemControl/NewsAsideItemControl';
import NewsAsideListControl from '../../NewsAside/NewsAsideListControl/NewsAsideListControl';

import defaultImage from '../../../../../images/object_mask_profile.svg';
import playIcon from '../../../../../images/play-icon.svg';
import FullScreenImage from '../../../Objects/ObjectsContent/FullScreenImage/FullScreenImage';

import NewsVideoPlaceholder from '../../../../../images/new-video-placeholder.jpeg';

const NewsItemContent = ({ item }) => {
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState(false);
  const { pathname } = useLocation();

  // console.log(item);

  const openFullScreenImg = (clickedPhoto) => {
    setCurrentPhoto(clickedPhoto);
    setShowFullScreenImage(true);
  };
  const next = () => {
    if (!currentPhoto) return;
    item.images.forEach((point, index) => {
      if (point.image === currentPhoto.image) {
        if (item.images[index + 1] === undefined) {
          setCurrentPhoto(item.images[0]);
        } else {
          setCurrentPhoto(item.images[index + 1]);
          return;
        }
      }
    });
  };
  const prev = () => {
    if (!currentPhoto) return;
    item.images.forEach((point, index) => {
      if (point.image === currentPhoto.image) {
        if (item.images[index - 1] === undefined) {
          setCurrentPhoto(item.images[item.images.length - 1]);
        } else {
          setCurrentPhoto(item.images[index - 1]);
          return;
        }
      }
    });
  };

  if (item.images.length === 0) {
    item.images.push({ image: defaultImage, cropped_image: defaultImage });
  }
  return (
    <>
      {/* <NewsAsideListControl /> */}

      <div className={styles.content}>
        <div className={styles.head}>
          <NewsItemContentHead
            title={item.title}
            date={item.date_to_show}
            // videoLink={item.videos[0]}
          />
        </div>
        <div className={styles.slider}>
          {showFullScreenImage && !isMobile && (
            <FullScreenImage
              imageList={item.images}
              prev={prev}
              next={next}
              current={currentPhoto.image}
              close={() => setShowFullScreenImage(false)}
            />
          )}
          {item.images && (
            <SliderNews
              item={item.images}
              openFullScreenImg={openFullScreenImg}
            />
          )}
          {item.videos.length > 0 && (
            <div>
              {item.videos.map((point, index) => (
                <div key={index} className={styles.video_item}>
                  <a
                    href={point.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <div className={styles.image_wrap}>
                      <img
                        src={
                          point.cropped_photo
                            ? point.cropped_photo
                            : NewsVideoPlaceholder
                        }
                        alt=''
                      />
                      <div>
                        <img src={playIcon} alt='' />
                      </div>
                    </div>
                    {point.title && <p>{point.title}</p>}
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.filling}>
          <NewsItemContentFilling
            text={item.full_text}
            documents={item.files}
            author={item.photo_description}
            objects={item.improvement_objects}
          />
        </div>

        {!isMobile && (
          <div className={styles.back}>
            <ButtonBack
              to={pathname.includes('media') ? '/news/media' : '/news/events'}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default NewsItemContent;
