import React from "react";
import AsideFixedBox from "../../../universal_components/Aside/AsideFixedBox/AsideFixedBox";
import MainContainerLeftControl from "../../../universal_components/MainContainerLeftControl/MainContainerLeftControl";

import styles from "./VacanciesWrapper.module.scss";
import { isMobile } from "react-device-detect";
import VacancyDescription from "./VacancyDescription/VacancyDescription";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const VacanciesWrapper = () => {
  const [vacancies, setVacancies] = useState();
  const [activeVacancy, setActiveVacancies] = useState();
  const vacanciesRedux = useSelector((state) => state.vacancies.vacancies);
  const activeVacancyRedux = useSelector((state) => state.vacancies.activeVacancy);

  useEffect(() => {
    if (!vacanciesRedux) return;
    setVacancies(vacanciesRedux);
  }, [vacanciesRedux]);
  
  useEffect(() => {
    if (!activeVacancyRedux) return;
    setActiveVacancies(activeVacancyRedux);
  }, [activeVacancyRedux]);

  const options = "250px";
  return (
    <section className={styles.about}>
      <MainContainerLeftControl widthAside={options} data={<AsideFixedBox vacancies={vacancies ? vacancies : []} />}>
        {isMobile && (
          <>
            <h2 className="title" style={{ marginBottom: 20, fontSize: 21, fontWeight: 600 }}>
              О НАС
            </h2>
            <div style={{ marginBottom: 27 }}>
              <AsideFixedBox vacancies={vacancies ? vacancies : []} />
            </div>
          </>
        )}
        <VacancyDescription vacancy={activeVacancy} vacancies={vacancies ? vacancies : []} />
      </MainContainerLeftControl>
    </section>
  );
};

export default VacanciesWrapper;
