import React, { useState } from "react";
import ButtonClose from "../../../../../../universal_components/ButtonClose/ButtonClose";
import SearchPurchases from "../../../../../../universal_components/SearchPurchases/SearchPurchases";
import styles from "./RegistrItem.module.scss";
import RegistrMainInfo from "./RegistrMainInfo/RegistrMainInfo";
import RegistrSecondaryInfo from "./RegistrSecondaryInfo/RegistrSecondaryInfo";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";

const RegistrItem = ({ data }) => {
  const [isShow, setShow] = useState(false);

  const { type } = useParams();

  useEffect(() => {
    setShow(false);
  }, [type])

  return (
    <li className={styles.item}>
      <RegistrMainInfo
        link={data.link}
        accreditation_direction={data.accreditation_direction}
        short_name={data.short_name}
        description={data.description}
        documents={data.documents}
        improvement_objects={data.improvement_objects}
        contacts={data.contacts}
        isShow={isShow}
        onChange={() => (data ? setShow(!isShow) : setShow(false))}
      />
      {isShow && (
        <RegistrSecondaryInfo
          full_name={data.full_name}
          improvement_objects={data.improvement_objects}
          short_name={data.short_name}
          link={data.link}
          description={data.description}
          documents={data.documents}
        />
      )}
    </li>
  );
};

export default RegistrItem;
