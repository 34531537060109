import { getOrganizationsAction } from "../redux/Reducers/organizationsReducer";
import { BASE_URL } from "./BASE_URL";

export const getOrganizations = () => {
  return async (dispatch) => {
    const response = await fetch(
      BASE_URL + "purchases/organizations/?limit=550"
    );
    const data = await response.json();

    if (response.status === 200) {
      dispatch(getOrganizationsAction(data.results));
    }
  };
};
