import React from "react";
import { useState } from "react";
import styles from "./PurchasesInfo.module.scss";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import PurchasesAside from "../../PurchasesAside/PurchasesAside";

const PurchasesInfo = () => {
  const [documents, setDocuments] = useState();

  const docs = useSelector((state) => state.procurements.procurements);

  const windowWidth = window.innerWidth;

  const getExtention = (string) => {
    const indexDot = string.lastIndexOf(".");
    return string.slice(indexDot);
  };

  useEffect(() => {
    if (!docs) return;
    setDocuments(docs);
  }, [docs]);
  return (
    <>
      {isMobile && (
        <>
          <h3 style={{ fontSize: 21, fontWeight: 600, marginBottom: 24 }}>
            ЗАКУПКИ
          </h3>
          <PurchasesAside />
        </>
      )}
      <section className={styles.info}>
        <p className={styles.description}>
          <span>
            Общий порядок организации и проведения закупки товаров, работ, услуг
            в Институте, основные требования к элементам закупочного процесса, а
            также иные связанные с организацией закупочной деятельности
            положения регулируются Положением о закупке товаров, работ, услуг,
            утвержденным приказом АНО.
          </span>
        </p>
        {documents && (
          <div className={styles.links}>
            {documents.map((item, index) => (
              <p key={index} className={styles.link}>
                <a href={item.file} target="_blank">
                  <span>{item.name}</span>
                  <span>{getExtention(item.file)}</span>
                </a>
              </p>
            ))}
          </div>
        )}
      </section>
    </>
  );
};

export default PurchasesInfo;
