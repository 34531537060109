import React, { useEffect, useState } from 'react';
import SearchPurchases from '../../../../universal_components/SearchPurchases/SearchPurchases';
import styles from './PurchasesAggregator.module.scss';
import AggregatorTable from './AggregatorTable/AggregatorTable';
import PurchasesAside from '../../PurchasesAside/PurchasesAside';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import PurchasesFilter from './AggregatorTable/PurchasesFilter';

const PurchasesAggregator = ({ data }) => {
  const [searchFiled, setSearchField] = useState();
  const [activeFilter, setActiveFilter] = useState();
  const { type } = useParams();

  // useEffect(() => {
  //   setSearchField("");
  //   setActiveFilter();
  // }, [type]);

  const windowWidth = window.innerWidth;

  return (
    <div className={styles.agr}>
      {isMobile && (
        <>
          <h3 style={{ fontSize: 21, fontWeight: 600, marginBottom: 24 }}>
            ЗАКУПКИ
          </h3>
          <PurchasesAside />
        </>
      )}
      <p>
        Универсальная онлайн-платформа, консолидирующая размещение запросов и
        закупок в сфере благоустройства общественных пространств и
        пространственного развития территорий муниципальных образований края с
        различных электронных торговых площадок в один ресурс на сайте
        Института. Агрегатор отражает для потенциальных поставщиков и
        подрядчиков торговые процедуры по разработке эскизных проектов и
        проектно-сметной документации, проведению строительно-монтажных работ,
        поставке элементов благоустройства, авторскому надзору и прочим работам.
      </p>
      <div className={styles.search}>
        <PurchasesFilter onChange={(filter) => setActiveFilter(filter)} />
        <SearchPurchases
          value={searchFiled}
          onChange={(text) => setSearchField(text)}
        />
      </div>

      <AggregatorTable
        data={data}
        searchFiled={searchFiled}
        activeFilter={activeFilter}
      />
    </div>
  );
};

export default PurchasesAggregator;
