import React from "react";
import styles from "./PartnersItem.module.scss";

const PartnersItem = ({ item, onClick, isActive }) => {
  return (
    <div className={!isActive ? styles.item : styles.activeItem}>
      <li>
        <button
          onClick={() => {
            onClick(item.id);
          }}
        >
          <img src={item.logo} alt="" />
        </button>
      </li>
    </div>
  );
};

export default PartnersItem;
