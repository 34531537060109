import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

import defaultImage from '../../../../../images/default-Img-about.svg';
import mobileDefaultImage from '../../../../../images/default-Img-about-mob.svg';

import styles from "./WorkersItem.module.scss";
import { isMobile } from 'react-device-detect';

const WorkersItem = ({ photo, fio, position, email, phone }) => {
  const [imageWidth, setImageWidth] = useState()
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    const resizeImg = () => {
        setImageWidth(ref.current.clientWidth)
    }
    window.addEventListener('resize', resizeImg())
    return () => window.removeEventListener("resize", resizeImg);
  }, [ref.current])

  return (
    <div className={styles.item}>
      {photo ? (
        <div ref={ref} className={styles.img} style={{backgroundImage: 'url(' + photo + ')', height: imageWidth}}></div>
      ) : (
        <img src={defaultImage}  className={styles.img} />
      )}
      <div className={styles.textWrapper}>
        <p>{fio}</p>
        {position && <span className={styles.position}>{position}</span>}
        {phone && (
          <a href={"tel:" + phone} className={styles.phone}>
            {phone}
          </a>
        )}
        {email && (
          <a href={"mailto:" + email} className={styles.email}>
            {email}
          </a>
        )}
      </div>
    </div>
  );
};

export default WorkersItem;
