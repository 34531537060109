import React from "react";
import styles from "./NewsItem.module.scss";
// import images from "../../../../../../images/test-town_6.jpg";
import { Link, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const NewsItem = (props) => {
  const {ref, inView} = useInView({
    threshold: 0.9,
    triggerOnce: true,
  })

  console.log(props);

  const oldDate = props.date;

  function formatDate(date) {
    if (typeof date == "number") {
      // перевести секунды в миллисекунды и преобразовать к Date
      date = new Date(date * 1000);
    } else if (typeof date == "string") {
      // строка в стандартном формате автоматически будет разобрана в дату
      date = new Date(date);
    } else if (Array.isArray(date)) {
      date = new Date(date[0], date[1], date[2]);
    }

    // преобразования для поддержки полиморфизма завершены,
    // теперь мы работаем с датой (форматируем её)
    return date.toLocaleString("ru", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }
  const location = useLocation();

  return (
    <li ref={ref} className={styles.item}>
      {inView ?
        <>
          <Link to={location.pathname + "/" + props.id}>
            <p className={styles.title}>{props.title}</p>
            <div className={styles.image} style={{
              backgroundImage: `url(${props.img})`
              }}></div>
              {/* <img src={props.img} alt="" className={styles.image} /> */}
            <p className={styles.date}>{formatDate(oldDate)}</p>
          </Link>
        </>
        :
        <div className={styles.skeleton} />
      }
    </li>
  );
};

export default NewsItem;
