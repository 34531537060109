import { create } from "zustand";

export const useManufactureFilter = create(set => ({
    manufactures: [''],
    setManufacture: (type) => set((state) => {
        console.log(state)
        console.log(type)
        if(state.manufactures.includes(type)){
            console.log('1', state)
            return {...state, manufactures: state.manufactures.filter(e => e !== type)}
        } else {
            console.log('2', state)
            return {...state, manufactures: [...state.manufactures, type]}
        }
    })
}))
