const initialState = {
  documents: [],
  standarts: [],
  categories: [],
};

const GET_DOCUMENTS = "GET_DOCUMENTS";
const GET_STANDARTS = "GET_STANDARTS";
const GET_DOCUM_CATEGORIES = "GET_DOCUM_CATEGORIES";

export const documReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return { ...state, documents: action.payload };
    case GET_STANDARTS:
      return { ...state, standarts: action.payload };
    case GET_DOCUM_CATEGORIES:
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};

export const getDocumentsAction = (payload) => ({
  type: GET_DOCUMENTS,
  payload,
});
export const getDocumCategoriesAction = (payload) => ({
  type: GET_DOCUM_CATEGORIES,
  payload,
});
export const getStandartsAction = (payload) => ({
  type: GET_STANDARTS,
  payload,
});
