import { getExpertsAction, getExpertsTextAction } from '../redux/Reducers/expertsReducer';
import { BASE_URL } from './BASE_URL';

export const getExperts = () => {
    return async (dispatch) => {
        const response = await fetch(BASE_URL + 'team/experts_board/');
        const data = await response.json();

        if (response.status === 200) {
          dispatch(getExpertsAction(data.results))
        }
    }
}

export const getExpertsText = () => {
  return async (dispatch) => {
      const response = await fetch(BASE_URL + 'index/experts_text/');
      const data = await response.json();

      if (response.status === 200) {
        dispatch(getExpertsTextAction(data.text))
      }
  }
}