import React from "react";
import MainContainerLeftControl from "../../universal_components/MainContainerLeftControl/MainContainerLeftControl";
import styles from "./Purchases.module.scss";
import PurchasesAside from './PurchasesAside/PurchasesAside';
import PurchasesContent from './PurchasesContent/PurchasesContent';
import PurchasesInfo from './PurchasesContent/PurchasesInfo/PurchasesInfo';
import { ReactComponent as DecorPurchasesImage } from "./../../../images/decor.svg";
import { ReactComponent as MobileDecorPurchasesImage } from "./../../../images/mobileDecor.svg";
import { isMobile } from 'react-device-detect';


const PurchasesInfoWrap = () => {
  // ширина боковой колонки
  const options = "300px";

  return (
    <div className={styles.purch}>
      <MainContainerLeftControl widthAside={options} data={<PurchasesAside /> }>
        {isMobile && <h2 className="title"></h2>}
        <PurchasesContent children={<PurchasesInfo />} />
      </MainContainerLeftControl>
      <div className={styles.decorWrapper}>
        {isMobile ? <MobileDecorPurchasesImage /> : <DecorPurchasesImage />}
      </div>
    </div>
  );
};

export default PurchasesInfoWrap;
