import classnames from 'classnames';
import React from 'react';
import { useState } from 'react';
import styles from './ObjectControlYear.module.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const ObjectControlYear = ({
  modal,
  onChangeHandler,
  filtersArray,
  filter,
}) => {
  const [years, setYears] = useState();
  const [firstSixYears, setFirstSixYears] = useState();
  const [showAllYears, setShowAllYears] = useState(false);

  const reduxYears = useSelector((state) => state.objects.years);

  useEffect(() => {
    if (reduxYears.lenght < 1) return;
    const sorted = reduxYears.sort((a, b) => {
      return b - a;
    });
    setYears(sorted);

    const array = [];
    sorted.map((item, index) => index < 4 && array.push(item));
    setFirstSixYears(array);
  }, [reduxYears]);

  return (
    years && (
      <fieldset
        className={classnames(
          modal ? [styles.year, styles.modal] : [styles.year]
        )}
      >
        <legend className='visually-hidden'>Год</legend>
        <h3>Год</h3>
        <div
          className={styles.list}
          style={
            showAllYears
              ? {
                  position: isMobile ? 'relative' : 'absolute',
                  marginTop: isMobile ? 0 : 35,
                  zIndex: 2,
                  backgroundColor: '#FFFFFF',
                  padding: isMobile ? 0 : 10,
                  paddingBottom: isMobile ? 0 : 25,
                  width: '105%',
                  boxShadow: '0px 8px 7px -2px rgba(0,0,0,0.75)',
                }
              : {
                  position: isMobile ? 'relative' : 'absolute',
                  marginTop: isMobile ? 0 : 35,
                  zIndex: 2,
                  backgroundColor: '#FFFFFF',
                  padding: isMobile ? 0 : 10,
                  paddingBottom: isMobile ? 0 : 25,
                  width: '105%',
                }
          }
        >
          {!modal
            ? years && showAllYears
              ? years.map((item, index) => (
                  <label key={index}>
                    <input
                      className='visually-hidden'
                      type='checkbox'
                      checked={filter?.years.includes(item)}
                      onChange={(e) => onChangeHandler(item)}
                    />
                    <span className={styles.mark}></span>
                    <span className={styles.name}>{item}</span>
                  </label>
                ))
              : years &&
                !showAllYears &&
                firstSixYears.map((item, index) => (
                  <label key={index}>
                    <input
                      className='visually-hidden'
                      type='checkbox'
                      checked={filter?.years.includes(item)}
                      onChange={(e) => onChangeHandler(item)}
                    />
                    <span className={styles.mark}></span>
                    <span className={styles.name}>{item}</span>
                  </label>
                ))
            : years.map((item, index) => (
                <label key={index}>
                  <input
                    className='visually-hidden'
                    type='checkbox'
                    checked={filter?.years.includes(item)}
                    onChange={(e) => onChangeHandler(item)}
                  />
                  <span className={styles.mark}></span>
                  <span className={styles.name}>{item}</span>
                </label>
              ))}
          {years && years.length > 4 && (
            <button
              type='button'
              onClick={() => setShowAllYears(!showAllYears)}
            >
              {showAllYears ? 'Свернуть' : 'Показать все года'}
            </button>
          )}
        </div>
      </fieldset>
    )
  );
};

export default ObjectControlYear;
