import React, { Component } from "react";
import Slider from "react-slick";
import styles from "./SliderPartners.module.scss";
import classnames from "classnames";
import PartnersItemMobile from "../../pages/Partners/PartnersContent/PartnersItemMobile/PartnersItemMobile";
import { isMobile } from 'react-device-detect';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './defaultStyles.scss';

export default class SliderPartners extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCurrent = () => {
    if (this.props.array && this.props.array.length > 0) {
      const currentSlider = document.querySelector(".parent_slider");
      const currentSlide = currentSlider.querySelector(".slick-current");
      const currentImageId = currentSlide.querySelector("img").id;
      this.props.showNewPartner(currentImageId);
    }
  };

  componentDidMount() {
    this.getCurrent();
  }
  render() {
    const settings = {
      dots: false,
      fade: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
    };

    return (
      <div className={styles.slider} class="parent_slider">
        <Slider
          {...settings}
          slidesToShow={1}
          arrows={true}
          afterChange={(current) => {
            this.getCurrent();
          }}
          onInit={() => {
            this.getCurrent();
          }}
          onReInit={() => {
            this.getCurrent();
          }}
          className="center"
          centerMode={true}
          centerPadding={0}
        >
          {this.props.array &&
            this.props.array.map((item, index) => (
              <PartnersItemMobile
                id={item.id}
                key={index}
                onClick={this.props.showNewPartner}
                item={item}
                // isActive={
                //   this.props.activePartner && this.props.activePartner.id === item.id && true
                // }
              />
            ))}
        </Slider>
      </div>
    );
  }
}
