const initialState = {
    procurements: [],
}

const GET_PROCUREMENTS = 'GET_PROCUREMENTS';

export const procurementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROCUREMENTS:
            return {...state,
                procurements: action.payload,
            }
        default:
            return state;
    }
}

export const getProcurementsAction = (payload) => ({type: GET_PROCUREMENTS, payload})