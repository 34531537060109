import React from "react";
import styles from "./Modal.module.scss";
import classnames from "classnames";

import closeIcon from '../../../images/close.png';

const Modal = ({ active, setActive, children }) => {
  return (
    <div
      className={classnames(
        active ? [styles.modal, styles.active] : [styles.modal]
      )}
      onClick={() => setActive(false)}
    >
      <div
        className={classnames(
          active ? [styles.content, styles.active] : [styles.content]
        )}
        onClick={(e) => e.stopPropagation()}
      >
      <img src={closeIcon} alt="Закрыть" className={styles.close} onClick={() => setActive(false)} />
        {children}

      </div>
      
    </div>
  );
};

export default Modal;
