import { getContestsAction } from "../redux/Reducers/contestsReducer";

export const getContests = () => {
  return async (dispatch) => {
    const response = await fetch(
      "https://konkurs.ingorod24.ru/api/v1/contests/"
    );
    const data = await response.json();

    if (response.status === 200) {
      dispatch(getContestsAction(data));
    }
  };
};
