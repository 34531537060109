import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import styles from './MPs.module.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import defaultImage from '../../../../images/object_mask_profile.svg';
import { useState } from 'react';
import { formatDate } from '../../../../functions/formatDate';

const MPs = ({ text, text2 }) => {
  const location = useLocation();
  const [news, setNews] = useState([]);
  const MPNewsRedux = useSelector((state) => state.MPNews.news);
  const MPsRedux = useSelector((state) => state.MPs.MPs);

  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location]);

  useEffect(() => {
    if (!MPNewsRedux) return;
    MPNewsRedux.map((item) => {
      if (item.images.length === 0) {
        item.images.push({ image: defaultImage, cropped_image: defaultImage });
      }
    });
    setNews(MPNewsRedux);
  }, [MPNewsRedux]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>
        <p dangerouslySetInnerHTML={{ __html: text2.text }}></p>
      </div>
      <div className={styles.cards}>
        {MPsRedux.map((MP) => (
          <div key={MP.id} className={styles.card}>
            <img src={`https://ingorod24.ru/${MP.preview}`} alt='preview' />
            <NavLink className={styles.button} to={`/masterplan/${MP.id}`}>
              {MP.title}
            </NavLink>
          </div>
        ))}
      </div>
      <div className={styles.text}>
        <h2>{text.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: text.text }}></p>
      </div>
      <div id='news' className={styles.news}>
        {news.map((item) => (
          <Link
            to={
              `/news/${
                item.category == 'event' ? item.category + 's' : item.category
              }/` + item.id
            }
            key={item.id}
            className={styles.new}
          >
            <img
              className={styles.newImage}
              src={`https://ingorod24.ru/${item?.images[0]?.image}`}
            />
            <p className={styles.newDate}>{formatDate(item.date_to_show)}</p>
            <p className={styles.newTitle}>{item.title}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MPs;
