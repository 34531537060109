import React from "react";
import { useEffect } from "react";
import styles from "./MapAbout.module.scss";
import DG from '2gis-maps';

import iconMarker from '../../../../../images/map_marker_active.svg'

const MapAbout = () => {
  let screenWidth = window.screen.width;
  window.addEventListener("resize", function () {
    screenWidth = window.screen.width;
    return screenWidth;
  });

  useEffect(() => {
    let map = DG.map("map", {
      center: [56.015072, 92.855779],
      zoom: 15,
    });

    let icon = DG.icon({
      iconUrl: iconMarker,
      iconSize: [30, 30],
    });

    DG.marker([56.014438, 92.853326], { icon: icon }).addTo(map).bindPopup("<p>     Мы здесь</p><span>Обороны 3</span>").openPopup()
    return () => map.remove();
  }, [])

  return (
    <div className={styles.map}>
      <div id="map" className="default" style={{
        width: "100%",
        height: screenWidth > 768 ? 400 : 200,
      }}></div>
      {/* <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab8fec906c3c50f9bfb8caf1d532f0ead99aef28c5eaddc1252afc6f96d6fd25a&amp;source=constructor"
        width="100%"
        height={screenWidth > "768" ? "400" : "200"}
        frameBorder="0"
      ></iframe> */}

      <p>56.014438, 92.853326</p>
    </div>
  );
};

export default MapAbout;
