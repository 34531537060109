import React from "react";
import styles from "./PartnersAside.module.scss";
import mobileStyles from "./PartnersAsideMobile.module.scss";
import { isMobile } from "react-device-detect";

const PartnersAside = ({ item }) => {
  const linkFormatter = (link) => {
    const deleteCount = link.includes('http:') ? 7 : 8;

    return link.slice(deleteCount)
  }
  return (
    <>
      {isMobile ? (
        <div className={mobileStyles.aside}>
          <h3>{item.name}</h3>
          <p className={mobileStyles.name}>{item.contact_fio}</p>
          <a className={mobileStyles.tel} href={"tel:" + item.contact_phone}>
            <span>{item.contact_phone}</span>
          </a>
          <p className={mobileStyles.email}>{item.contact_email}</p>
          <a className={mobileStyles.link} href={item.link} target="_blank">
            <span>{linkFormatter(item.link)}</span>
          </a>
        </div>
      ) : (
        <div className={styles.aside}>
          <h3>{item.name}</h3>
          <p className={styles.name}>{item.contact_fio}</p>
          <a className={styles.tel} href={"tel:" + item.contact_phone}>
            <span>{item.contact_phone}</span>
          </a>
          <p className={styles.email}>{item.contact_email}</p>
          <a className={styles.link} href={item.link} target="_blank">
            <span>{linkFormatter(item.link)}</span>
          </a>
        </div>
      )}
    </>
  );
};

export default PartnersAside;
