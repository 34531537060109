import React from "react";
import styles from "./History.module.scss";

const History = () => {
  return (
    <div className={styles.history}>
      <h2>История</h2>
      <p>
        Институт города был создан в 2020 как региональный институт развития по
        вопросам планирования пространственного
        (архитектурно-градостроительного) развития территорий муниципальных
        образований Красноярского края.
      </p>
      <p>
        Учредителями Института выступают ФГАОУ ВО «Сибирский федеральный
        университет», Красноярский край в лице министерства строительства
        Красноярского края и Муниципальное образование городской округ город
        Красноярск Красноярского края в лице Администрации города Красноярска.
      </p>
      <p>
        В соответствии с распоряжением Правительства Красноярского края Институт
        осуществляет функции регионального центра компетенций по вопросам
        городской среды на территории муниципальных образований Красноярского
        края.
      </p>
      <p>
        В рамках своей уставной деятельности и нормативно-методического
        сопровождения муниципальных образований Красноярского края Институт
        выступает:
        <span>
          заказчиком разработки концепций благоустройства и проектно-сметной
          документации по благоустройству общественных пространств на территории
          муниципальных образований края в рамках реализации федерального
          проекта «Формирование комфортной городской среды».
        </span>
        <span>
          заказчиком разработки документов (регламентов, стандартов, типовых
          решений) по улучшению архитектурно-художественного облика и
          формированию комфортной среды населённых пунктов.
        </span>
        <span>
          постоянно действующей дискуссионной площадкой по экспертному
          рассмотрению значимых для города Красноярска и Красноярского края
          вопросов городского развития, вовлечению в обсуждение городских
          преобразований бизнеса, местных жителей и локальных сообществ.
        </span>
      </p>
    </div>
  );
};

export default History;
