import React, { Component, useEffect, useRef } from "react";
import Slider from "react-slick";
import styles from "./SliderObjects.module.scss";
import image from "../../../images/logo-blue.svg";
import { useState } from "react";
// import classnames from "classnames";
import { isMobile } from 'react-device-detect';

export default class SliderObjects extends Component {
  constructor(props) {
    super(props);
    this.mainImageWidth = React.createRef();
    this.smallImageWidth = React.createRef();
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }


  render() {
    const windowWidth = window.innerWidth;
    const container = document.getElementById('container')
    const containerWidth = parseInt(getComputedStyle(container, null).width) - parseInt(getComputedStyle(container, null).paddingLeft) - parseInt(getComputedStyle(container, null).paddingRight)

    const secondSlider = document.querySelector('.slick-wrapper')
    const list = secondSlider && secondSlider.querySelector('.slick-list')

    const slidesToShow = windowWidth > 768 && windowWidth < 1001 ? this.props.item.length >= 6 ? 6 : this.props.item.length : this.props.item.length >= 3 ? 3 : this.props.item.length

    if (list) {
      list.style.width = containerWidth;
    } 

    return (
      <div className={styles.slider}>
        <Slider
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          arrows={false}
          adaptiveHeight={true}
        >
          {this.props.item &&
            this.props.item.map((item, index) => (
              <div key={index}>
                <div
                  ref={this.mainImageWidth}
                  onClick={() => this.props.openFullScreenImg(item.photo)}
                  style={
                    !isMobile ? {
                    width: 333,
                    height: 267,
                    backgroundImage: 'url('+ item.photo +')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    marginBottom: 13
                  } : (windowWidth > 768 && windowWidth < 1001 ? {
                    width: 700,
                    height: 400,
                    backgroundImage: 'url('+ item.photo +')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    marginBottom: 20
                  } : {
                    width: containerWidth,
                    height: this.mainImageWidth.current && this.mainImageWidth.current.clientWidth * 0.8,
                    backgroundImage: 'url('+ item.photo +')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    marginBottom: (containerWidth - (containerWidth * 0.3 * 3)) / 4
                  })}
                ></div>
                {/* <img src={item.image} alt="" style={{height: 400}} /> */}
              </div>
            ))}
        </Slider>
        {/* <img src={image} className={styles.decorImgae} style={isMobile ? windowWidth > 768 ? {top: 341 + 'px'} : {top: 121 + 'px'} : {top: 188  + 'px'}} /> */}
        <div className="slick-wrapper" style={{marginBottom: 1}}>
        <Slider
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          slidesToShow={windowWidth > 768 && windowWidth < 1001 ? this.props.item.length >= 6 ? 6 : this.props.item.length : this.props.item.length >= 3 ? 3 : this.props.item.length }
          swipeToSlide={true}
          focusOnSelect={true}
          arrows={false}
          beforeChange={(prev, next) => {
            this.setState({ currentSlide: next });
          }}
          className={this.props.item.length >= 3 ? "center" : "slider variable-width"}
          variableWidth={this.props.item.length >= 3 ? false : true}
          centerMode={this.props.item.length >= 3 ? true : false}
          centerPadding={this.props.item.length >= 3 ? !isMobile ? -5 : (windowWidth > 768 && windowWidth < 1001 ? -11 : -7) : 10}
          dots={true}
          appendDots={dots => (
            <div style={{bottom: '-33px'}}>
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          )}
          customPaging={i => (
            <div
              style={
                i === this.state.currentSlide ? {
                width: 11,
                height: 11,
                border: "1px solid #00405B",
                borderRadius: '50%',
                backgroundColor: '#00405B',
              } : {
                width: 11,
                height: 11,
                border: "1px solid #FF2E17",
                borderRadius: '50%'
              }}
            >
              {/* {i + 1} */}
            </div>
          ) }
        >
          {this.props.item &&
            this.props.item.map((point, index) => (
              <div key={index}  style={{ width: 100 }}>
                <div
                  className="item"
                  style={
                    !isMobile ? {
                    width: 90,
                    height: 90,
                    backgroundImage: 'url('+ point.photo +')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  } : {
                    width: containerWidth / slidesToShow - 10,
                    height: containerWidth / slidesToShow - 10,
                    backgroundImage: 'url('+ point.photo +')',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    // marginBottom: 20
                  }}
                ></div>
                {/* <img src={item.image} alt="" style={{width: 90, height: 63}} /> */}
              </div>
            ))}
        </Slider>
        </div>
      </div>
    );
  }
}
