const initialState = {
    workers: [],
}

const GET_WORKERS = 'GET_WORKERS';

export const workersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_WORKERS:
            return {...state,
                workers: action.payload,
            }
        default:
            return state;
    }
}

export const getWorkersAction = (payload) => ({type: GET_WORKERS, payload})