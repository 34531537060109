import React from 'react';

import styles from './PurchasesRegistr.module.scss';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as ShowFilterArrow } from '../../../../../images/show-filter-arrow.svg';
import { useParams } from 'react-router-dom';

const RegistryFilter = ({ onChange, searchFiled }) => {
  const [activeFilter, setActiveFilter] = useState()
  const [filterArray, setFilterArray] = useState()
  const [isShowFilter, setShowFilter] = useState(false)

  const { type } = useParams();

  useEffect(() => {
    setActiveFilter();
  }, [type])

  const design_accreditation_direction = useSelector(state => state.organizations.design_accreditation_direction)
  const local_accreditation_direction = useSelector(state => state.organizations.local_accreditation_direction)
  const contractor_accreditation_direction = useSelector(state => state.organizations.contractor_accreditation_direction)

  const listItemClickHandler = (elem) => {
    setActiveFilter(elem)
    onChange(elem)
    setShowFilter(false)
  }

  useEffect(() => {
    listItemClickHandler()
  }, [searchFiled])

  useEffect(() => {
    if (type === 'local') {
      if (!local_accreditation_direction) return;
      local_accreditation_direction.sort((a, b) => a.title > b.title ? 1 : -1);
      setFilterArray(local_accreditation_direction)
    }
    if (type === 'project') {
      if (!design_accreditation_direction) return;
      design_accreditation_direction.sort((a, b) => a.title > b.title ? 1 : -1);
      setFilterArray(design_accreditation_direction)
    }
    if (type === 'contractor') {
      if (!contractor_accreditation_direction) return;
      contractor_accreditation_direction.sort((a, b) => a.title > b.title ? 1 : -1);
      setFilterArray(contractor_accreditation_direction)
    }
  }, [contractor_accreditation_direction, design_accreditation_direction, local_accreditation_direction, type])

  return (
    <div className={styles.registryFilterWrap}>
        <p onClick={() => setShowFilter(!isShowFilter)}>{activeFilter ? activeFilter.title : 'Направления'}</p>
        <ShowFilterArrow style={isShowFilter ? {transform: 'rotate(180deg)'} : {}} onClick={() => setShowFilter(!isShowFilter)} />
        {filterArray && isShowFilter && (
            <ul className={styles.filterList}>
                    <li onClick={() => listItemClickHandler()}><div style={!activeFilter ? {backgroundColor: '#FF2E17'} : {}}></div> Все направления</li>
                {filterArray.map((item, index) => (
                    <li key={index} onClick={() => listItemClickHandler(item)}><div style={activeFilter && activeFilter.id === item.id ? {backgroundColor: '#FF2E17'} : {}}></div> {item.title}</li>
                ))}
            </ul>
        )}
    </div>
  )
}

export default RegistryFilter