import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styles from './DocumAside.module.scss';
import { useSelector } from 'react-redux';

const DocumAside = ({ onFilterChange }) => {
  const [categories, setCategories] = useState([]);

  const [activRegionalCategories, setActiveRegionalCategories] = useState([]);
  const [activMunicipalCategories, setActiveMunicipalCategories] = useState([]);
  const [activKRSKCategories, setActiveKRSKCategories] = useState([]);

  const [isRegionalCategories, setRegionalCategories] = useState(false);
  const [isMunicipalCategories, setMunicipalCategories] = useState(false);
  const [isKRSKCategories, setKRSKCategories] = useState(false);

  const DocCategory = useSelector((state) => state.documents.categories);

  const checkboxClickHandler = (activeCategories, id, changeState) => {
    if (activeCategories.includes(id)) {
      changeState(activeCategories.filter((item) => item !== id));
    } else {
      changeState((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    if (isMunicipalCategories) {
      setRegionalCategories(false);
      setActiveRegionalCategories([]);
      setKRSKCategories(false);
      setActiveKRSKCategories([]);
      return;
    } else {
      setActiveMunicipalCategories([]);
    }
  }, [isMunicipalCategories]);

  useEffect(() => {
    if (isRegionalCategories) {
      setMunicipalCategories(false);
      setActiveMunicipalCategories([]);
      setKRSKCategories(false);
      setActiveKRSKCategories([]);
      return;
    } else {
      setActiveRegionalCategories([]);
    }
  }, [isRegionalCategories]);

  useEffect(() => {
    if (isKRSKCategories) {
      setMunicipalCategories(false);
      setRegionalCategories(false);
      setActiveMunicipalCategories([]);
      setActiveMunicipalCategories([]);
      return;
    } else {
      setActiveKRSKCategories([]);
    }
  }, [isKRSKCategories]);

  useEffect(() => {
    setCategories(DocCategory);
  }, [DocCategory]);

  useEffect(() => {
    onFilterChange([
      ...activRegionalCategories,
      ...activMunicipalCategories,
      ...activKRSKCategories,
    ]);
  }, [activRegionalCategories, activMunicipalCategories, activKRSKCategories]);

  return (
    <ul className={styles.list}>
      {categories.length > 0 && (
        <li className={styles.item}>
          <button
            className={
              isRegionalCategories
                ? `${styles.button} ${styles.pressed}`
                : `${styles.button}`
            }
            onClick={() => setRegionalCategories(!isRegionalCategories)}
          >
            {categories[0].name}
          </button>

          <ul className={styles.filterbox}>
            {categories[0].subcategories?.map((item, index) => (
              <li className={styles.filteritem} key={index}>
                <label>
                  <input
                    type='checkbox'
                    className='visually-hidden'
                    checked={activRegionalCategories.includes(item.id)}
                    onChange={(e) =>
                      checkboxClickHandler(
                        activRegionalCategories,
                        item.id,
                        setActiveRegionalCategories
                      )
                    }
                  />
                  <span className={styles.mark}></span>
                  <span className={styles.name}>{item.name}</span>
                </label>
              </li>
            ))}
          </ul>
        </li>
      )}
      {categories.length > 0 && (
        <li className={styles.item}>
          <button
            className={
              isMunicipalCategories
                ? `${styles.button} ${styles.pressed}`
                : `${styles.button}`
            }
            onClick={() => setMunicipalCategories(!isMunicipalCategories)}
          >
            {categories[1].name}
          </button>

          <ul className={styles.filterbox}>
            {categories[1].subcategories?.map((item, index) => (
              <li className={styles.filteritem} key={index}>
                <label>
                  <input
                    type='checkbox'
                    className='visually-hidden'
                    checked={activMunicipalCategories.includes(item.id)}
                    onChange={(e) =>
                      checkboxClickHandler(
                        activMunicipalCategories,
                        item.id,
                        setActiveMunicipalCategories
                      )
                    }
                  />
                  <span className={styles.mark}></span>
                  <span className={styles.name}>{item.name}</span>
                </label>
              </li>
            ))}
          </ul>
        </li>
      )}
      {categories.length > 0 && (
        <li className={styles.item}>
          <button
            className={
              isKRSKCategories
                ? `${styles.button} ${styles.pressed}`
                : `${styles.button}`
            }
            onClick={() => setKRSKCategories(!isKRSKCategories)}
          >
            {categories[2].name}
          </button>

          <ul className={styles.filterbox}>
            {categories[2].subcategories?.map((item, index) => (
              <li className={styles.filteritem} key={index}>
                <label>
                  <input
                    type='checkbox'
                    className='visually-hidden'
                    checked={activKRSKCategories.includes(item.id)}
                    onChange={(e) =>
                      checkboxClickHandler(
                        activKRSKCategories,
                        item.id,
                        setActiveKRSKCategories
                      )
                    }
                  />
                  <span className={styles.mark}></span>
                  <span className={styles.name}>{item.name}</span>
                </label>
              </li>
            ))}
          </ul>
        </li>
      )}
    </ul>
  );
};

export default DocumAside;
