const initialState = {
  filters: JSON.parse(sessionStorage.getItem('filter')) || { programm: [], territory: '', years: [] },
};

const GET_FILTERS = 'GET_FILTERS';

export const objectFiltersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILTERS: {
      sessionStorage.setItem('filter', JSON.stringify(action.payload));
      return { ...state, filters: action.payload };
    }
    default:
      return state;
  }
};

export const getFiltersAction = (payload) => ({ type: GET_FILTERS, payload });
