import React, { useEffect, useState } from "react";

import MainInfo from "../MainInfo/MainInfo";
import SecondaryInfo from "../SecondaryInfo/SecondaryInfo";
import styles from "./AggregatorItem.module.scss";
import ButtonClose from '../../../../../../universal_components/ButtonClose/ButtonClose';
import { useParams } from 'react-router-dom';

const AggregatorItem = ({ data }) => {
  const [isShow, setShow] = useState(false);
  const { type } = useParams();

  useEffect(() => {
    setShow(false);
  }, [type])

  return (
    <li className={styles.item}>
      <MainInfo onChange={() => data ? setShow(!isShow) : setShow(false)} isShow={isShow} cost={data.cost} customer={data.customer} status={data.status} name={data.name} start_date={data.start_date} finish_date={data.finish_date} />
      {isShow && <SecondaryInfo description={data.description} customer={data.customer} improvement_objects={data.improvement_objects} documents={data.documents} />}
    </li>
  );
};

export default AggregatorItem;



