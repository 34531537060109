import React from "react";
import styles from "./AsideFixedBox.module.scss";
import BlueButton from "../../BlueButton/BlueButton";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import OtherVacancies from '../../../pages/About/Vacancies/OtherVacancies/OtherVacancies';
import { isMobile } from 'react-device-detect';

const AsideFixedBox = (props) => {
  let propertyButtons = [
    { name: "О компании", toggle: false, url: "/about/company" },
    { name: "Наблюдательный совет", toggle: false, url: "/about/supervisory" },
    { name: "Экспертный совет", toggle: false, url: "/about/experts" },
    { name: "Команда", toggle: false, url: "/about/workers" },
    { name: "Вакансии", toggle: false, url: "/about/vacancy" },
  ];

  const { pathname } = useLocation();

  return (
    <>
      <ul className={styles.fixedbox} style={{ width: props.widthAside }}>
        {propertyButtons.map((item) => {
          return (
            <BlueButton
              toggle={pathname === item.url}
              name={item.name}
              key={item.name}
              url={item.url}
            />
          );
        })}
      </ul>
      {props.vacancies && !isMobile && (<OtherVacancies vacancies={props.vacancies} />)}
    </>
  );
};

export default AsideFixedBox;
