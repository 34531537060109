const initialState = {
    purchases: [],
    activeAgregatorType: '',
    statusList: false,
}

const GET_PURCHASES = 'GET_PURCHASES';
const SET_ACTIVE_AGREGATOR_TYPE = 'SET_ACTIVE_AGREGATOR_TYPE'

export const purchasesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PURCHASES:
            const arr = [];
            action.payload.forEach(item => {
                if (!arr.includes(item.status)) arr.push(item.status)
            })
            return {...state,
                purchases: action.payload,
                statusList: arr,
            }
        case SET_ACTIVE_AGREGATOR_TYPE: 
            return {...state,
                activeAgregatorType: action.payload,
            }
        default:
            return state;
    }
}

export const getPurchasesAction = (payload) => ({type: GET_PURCHASES, payload})