import React from "react";
import styles from "./PageLogo.module.scss";
import logo from "../../../../images/logo-big.svg";
import logoSmall from "../../../../images/logo-blue.svg";
import { Link } from 'react-router-dom';

const PageLogo = () => {
  let screenWidth = window.innerWidth;
  // window.addEventListener("resize", function () {
  //   screenWidth = window.screen.width;
  //   return screenWidth;
  // });

  return (
    <Link className={styles.logo} to="/">
      <img src={screenWidth > 1000 ? logo : logoSmall} alt="" />
    </Link>
  );
};

export default PageLogo;
