import React from "react";
import styles from "./Activities.module.scss";

const Activities = () => {
  return (
    <div className={styles.activities}>
      <h2>Направления деятельности</h2>

      <ul className={styles.list}>
        <li>
          <h3>Городской диалог</h3>
          <p>
            Коммуникационная площадка для органов власти, экспертов, бизнеса,
            горожан и локальных сообществ.
          </p>
        </li>
        <li>
          <h3>Городская среда</h3>
          <p>
            Региональный центр компетенций по вопросам городской среды на
            территории муниципальных образований Красноярского края.
          </p>
        </li>
        <li>
          <h3>Городское планирование</h3>
          <p>
            Проектный офис по вопросам комплексного планирования
            пространственного развития территорий муниципальных образований
            края.
          </p>
        </li>
        <li>
          <h3>Городское развитие</h3>
          <p>
            Координационный центр по сопровождению реализации проектов
            комплексного развития территорий муниципальных образований края.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default Activities;
