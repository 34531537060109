import React from "react";
import SearchPurchases from "../../../../universal_components/SearchPurchases/SearchPurchases";
import styles from "./PurchasesRegistr.module.scss";
import RegistrTable from "./RegistrTable/RegistrTable";
import { isMobile } from "react-device-detect";
import PurchasesAside from "../../PurchasesAside/PurchasesAside";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import RigisrtryPurchasesAside from "../../PurchasesAside/RigisrtryPurchasesAside";
import RegistryFilter from "./RegistryFilter";

const PurchasesRegistr = ({ data }) => {
  const [searchFiled, setSearchField] = useState();
  const [activeFilter, setActiveFilter] = useState();
  const { type } = useParams();

  useEffect(() => {
    setSearchField('');
    setActiveFilter();
  }, [type])

  const windowWidth = window.innerWidth;

  return (
    <div className={styles.registr}>
      {(isMobile) && (
        <>
          <h3 style={{ fontSize: 21, fontWeight: 600, marginBottom: 24 }}>
            РЕЕСТР ПОСТАВЩИКОВ И ПОДРЯДЧИКОВ
          </h3>
          <RigisrtryPurchasesAside />
        </>
      )}
      <p>
        Реестр формируется из числа местных предприятий малого и среднего
        бизнеса – участников рынка благоустройства (архитектурные бюро,
        производители элементов благоустройства, строительно-монтажные
        организации). Реестр является рекомендацией для заказчиков при
        определении потенциального пула поставщиков и подрядчиков.
      </p>

      <div className={styles.search}>
        <RegistryFilter onChange={(filter) => setActiveFilter(filter)} searchFiled={searchFiled} />
        <SearchPurchases value={searchFiled} onChange={(text) => setSearchField(text)} />
      </div>

      <RegistrTable data={data} searchFiled={searchFiled} activeFilter={activeFilter} />
    </div>
  );
};

export default PurchasesRegistr;
