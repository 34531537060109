import React from "react";
import styles from "./ButtonClose.module.scss";

const ButtonClose = ({ open, onChange }) => {
  return (
    <button
    onClick={onChange}
      className={!open ? `${styles.button} ${styles.open}` : `${styles.button}`}
      type="button"
      data-text="Назад"
    ></button>
  );
};

export default ButtonClose;
