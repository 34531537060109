import { getVacanciesAction } from "../redux/Reducers/vacancyReducer";
import { BASE_URL } from "./BASE_URL";

export const getVacancies = () => {
  return async (dispatch) => {
    const response = await fetch(BASE_URL + "team/vacancies/?limit=550");
    const data = await response.json();

    if (response.status === 200) {
      dispatch(getVacanciesAction(data.results));
    }
  };
};
