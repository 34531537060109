import React from "react";
import MainContainerLeftControl from "../../universal_components/MainContainerLeftControl/MainContainerLeftControl";
import styles from "./Purchases.module.scss";
import PurchasesAside from "./PurchasesAside/PurchasesAside";
import PurchasesContent from "./PurchasesContent/PurchasesContent";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import PurchasesAggregator from "./PurchasesContent/PurchasesAggregator/PurchasesAggregator";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

const PurchasesAgreg = () => {
  const [data, setData] = useState();

  const purchases = useSelector(
    (state) => state.purchasesAggregators.purchases
  );
  const { type } = useParams();

  useEffect(() => {
    if (!purchases) return;
    if (type === "ano") {
      const ANO = purchases.filter((item) => item.category === "ano");
      setData(ANO);
    }
    if (type === "regions") {
      const REGIONS = purchases.filter((item) => item.category === "region");
      setData(REGIONS);
    }
    if (type === "city") {
      const CITY = purchases.filter((item) => item.category === "city");
      setData(CITY);
    }
  }, [purchases, type]);

  // ширина боковой колонки
  const options = "300px";
  return (
    <div className={styles.purch}>
      <MainContainerLeftControl widthAside={options} data={<PurchasesAside />}>
        {isMobile && <h2 className="title"></h2>}
        <PurchasesContent children={<PurchasesAggregator data={data} />} />
      </MainContainerLeftControl>
    </div>
  );
};

export default PurchasesAgreg;
