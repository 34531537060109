import { useEffect, useRef, useState } from 'react';
import styles from './Photos.module.scss';
import Slider from 'react-slick';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import closeImg from '../../../../images/closeFullScreenImg.svg';
import { ReactComponent as ArrowIcon } from '../../../../images/arrow-left-slider-news.svg';

const Photos = ({ photos }) => {
  const [isShowModal, setShowModal] = useState(false);
  const [mouseStartPosition, setMouseStartPosition] = useState();
  const [mouseEndPosition, setMouseEndPosition] = useState();
  const [currentImg, setCurrentImg] = useState();
  const [isShowNext, setIsShowNext] = useState(false);
  const [isShowPrev, setIsShowPrev] = useState(false);

  const width = useWindowWidth();
  const slider = useRef(null);

  useEffect(() => {
    if (!currentImg) return;
    const imgIndex = photos.findIndex((item) => item.image == currentImg);
    if (imgIndex === 0 && photos.length > 1) {
      setIsShowPrev(false);
      setIsShowNext(true);
    } else if (photos.length === 1) {
      setIsShowPrev(false);
      setIsShowNext(false);
    } else if (imgIndex === photos.length - 1) {
      setIsShowPrev(true);
      setIsShowNext(false);
    } else if (imgIndex === photos.length - 1 && imgIndex === 0) {
      setIsShowPrev(false);
      setIsShowNext(false);
    } else {
      setIsShowPrev(true);
      setIsShowNext(true);
    }
  }, [currentImg]);

  const settings = {
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const next = () => {
    const imgIndex = photos.findIndex((item) => item.image == currentImg);
    if (photos[imgIndex + 1]) setCurrentImg(photos[imgIndex + 1].image);
  };

  const prev = () => {
    const imgIndex = photos.findIndex((item) => item.image == currentImg);
    if (photos[imgIndex - 1]) setCurrentImg(photos[imgIndex - 1].image);
  };

  const openModal = (image) => {
    if (
      mouseEndPosition.x == mouseStartPosition.x &&
      mouseEndPosition.y == mouseStartPosition.y
    ) {
      setShowModal(true);
      setCurrentImg(image);
    }
  };

  return (
    <div className={styles.sliderWrapper}>
      {isShowModal && (
        <div className={styles.modalWrapper}>
          <img
            src={closeImg}
            className={styles.close}
            onClick={() => setShowModal(false)}
          />
          {isShowPrev && (
            <ArrowIcon className={styles.prevArrow} onClick={prev} />
          )}
          <img
            style={
              width > 768 ? { height: '80%', width: '80%' } : { width: '100%' }
            }
            src={currentImg}
            alt='image'
          />
          {isShowNext && (
            <ArrowIcon className={styles.nextArrow} onClick={next} />
          )}
        </div>
      )}
      <Slider className={styles.slider} {...settings}>
        {photos.map((item, id) => (
          <div
            onMouseDown={(e) =>
              setMouseStartPosition({ x: e.clientX, y: e.clientY })
            }
            onMouseUp={(e) =>
              setMouseEndPosition({ x: e.clientX, y: e.clientY })
            }
            onClick={() => openModal(item.image)}
            key={id}
            id='photo'
            className={styles.item}
          >
            <img className={styles.image} src={item.image} />
            <p className={styles.author}>{item.author}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Photos;
