import React, { useState } from "react";
import styles from "./BlueButton.module.scss";
import classnames from "classnames";
import { Link, NavLink } from "react-router-dom";

const BlueButton = (props) => {
  const [activeButton, setActiveButton] = useState(false);
  return (
    <li className={styles.item}>
      <NavLink to={props.url}>
      <button
        className={classnames(
          props.toggle ? [styles.button, styles.pressed] : [styles.button]
        )}
      >
        {props.name}
      </button>
      </NavLink>
    </li>
  );
};

export default BlueButton;
