import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ExpertsItem from "./ExpertsItem/ExpertsItem";

import styles from "./Experts.module.scss";

const ExpertsPage = () => {
  const [experts, setExperts] = useState();
  const [expertsText, setExpertsText] = useState();
  const expertsRedux = useSelector((state) => state.experts.experts);
  const expertsTextRedux = useSelector((state) => state.experts.text);

  useEffect(() => {
    if (!expertsRedux) return;
    setExperts(expertsRedux);
  }, [expertsRedux]);

  useEffect(() => {
    if (!expertsTextRedux) return;
    setExpertsText(expertsTextRedux);
  }, [expertsTextRedux]);

  return (
    <div className={styles.workersBox}>
      {expertsText && <p className={styles.mainDescription}>{expertsText}</p>}
      <div className={styles.workersWrapper}>
        {experts &&
          experts.map((item, index) => (
            <ExpertsItem
              key={index}
              photo={item.cropped_photo}
              fio={item.fio}
              position={item.description}
              email={item.email}
              phone={item.phone}
            />
          ))}
      </div>
    </div>
  );
};

export default ExpertsPage;
