import {
  getDocumCategoriesAction,
  getDocumentsAction,
  getStandartsAction,
} from "../redux/Reducers/documReducer";
import { BASE_URL } from "./BASE_URL";

export const getDocuments = () => {
  return async (dispatch) => {
    const response = await fetch(BASE_URL + "documents/?limit=550");
    const data = await response.json();

    if (response.status === 200) {
      const standarts = [];
      data.results.map((item) => {
        if (item.standard && !standarts.includes(item.standard.name)) {
          standarts.push(item.standard.name);
        }
      });
      dispatch(getStandartsAction(standarts));
      dispatch(getDocumentsAction(data.results));
    }
  };
};

export const getDocumCategories = () => {
  return async (dispatch) => {
    const response = await fetch(BASE_URL + "documents/categories/");
    const data = await response.json();

    if (response.status === 200) {
      dispatch(getDocumCategoriesAction(data.results));
    }
  };
};
