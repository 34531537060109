import classnames from 'classnames';
import React from 'react';
import styles from './ObjectControlProgramm.module.scss';

const ObjectControlProgramm = ({ modal, onChangeHandler, filtersArray }) => {
  return (
    <fieldset
      className={classnames(
        modal ? [styles.programm, styles.modal] : [styles.programm]
      )}
    >
      <legend className='visually-hidden'>Программа</legend>
      <h3>Программа</h3>
      <label>
        <input
          className='visually-hidden'
          type='checkbox'
          checked={filtersArray.programm?.includes('FCGC')}
          onChange={(e) => onChangeHandler('FCGC')}
        />
        <span className={styles.mark}></span>
        <span className={styles.name}>ФКГС</span>
      </label>

      <label>
        <input
          className='visually-hidden'
          type='checkbox'
          checked={filtersArray.programm?.includes('russian_competition')}
          onChange={(e) => onChangeHandler('russian_competition')}
        />
        <span className={styles.mark}></span>
        <span className={styles.name}>Всероссийский конкурс</span>
      </label>

      <label>
        <input
          className='visually-hidden'
          type='checkbox'
          checked={filtersArray.programm?.includes('KBY')}
          onChange={(e) => onChangeHandler('KBY')}
        />
        <span className={styles.mark}></span>
        <span className={styles.name}>КБУ</span>
      </label>

      <label>
        <input
          className='visually-hidden'
          type='checkbox'
          checked={filtersArray.programm?.includes('regional_competition')}
          onChange={(e) => onChangeHandler('regional_competition')}
        />
        <span className={styles.mark}></span>
        <span className={styles.name}>Краевой конкурс</span>
      </label>

      <label>
        <input
          className='visually-hidden'
          type='checkbox'
          checked={filtersArray.programm?.includes('pilot_territory')}
          onChange={(e) => onChangeHandler('pilot_territory')}
        />
        <span className={styles.mark}></span>
        <span className={styles.name}>Пилотные территории</span>
      </label>

      <label>
        <input
          className='visually-hidden'
          type='checkbox'
          checked={filtersArray.programm?.includes('road_service')}
          onChange={(e) => onChangeHandler('road_service')}
        />
        <span className={styles.mark}></span>
        <span className={styles.name}>Объекты дорожного сервиса</span>
      </label>
      <label>
        <input
          className='visually-hidden'
          type='checkbox'
          checked={filtersArray.programm?.includes('parks')}
          onChange={(e) => onChangeHandler('parks')}
        />
        <span className={styles.mark}></span>
        <span className={styles.name}>Городские парки</span>
      </label>
    </fieldset>
  );
};

export default ObjectControlProgramm;
