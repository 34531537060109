import React, { useEffect, useState } from 'react';
import styles from './ObjectContentFilters.module.scss';
import ObjectControlYear from './ObjectControlYear/ObjectControlYear';
import ObjectControlTerritory from './ObjectControlTerritory/ObjectControlTerritory';
import ObjectControlProgramm from './ObjectControlProgramm/ObjectControlProgramm';
import Modal from '../../../../../universal_components/Modal/Modal';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getFiltersAction } from '../../../../../../redux/Reducers/objectFiltersReducer';

const ObjectContentFilters = ({ filter, onFiltersChange }) => {
  const [modalActive, setModalActive] = useState(false);
  const [filtersArray, setFiltersArray] = useState(filter);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFiltersAction(filtersArray));
  }, [filtersArray]);

  // useEffect(() => {
  //   if (filter) {
  //     setFiltersArray(filter);
  //   }
  // }, [filter]);

  const filteringCount = useSelector((state) => state.objects.filteringCount);
  const isFiltered = useSelector((state) => state.objects.isFiltered);

  const changeWordEnding = (number, txt) => {
    var cases = [2, 0, 1, 1, 1, 2];
    return txt[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  };

  const changeTerritoryFilter = (item) => {
    setFiltersArray({ ...filtersArray, territory: item });
  };
  const changeProgramFilter = (item) => {
    if (filtersArray.programm.includes(item)) {
      setFiltersArray({
        ...filtersArray,
        programm: filtersArray.programm.filter((point) => point !== item),
      });
    } else {
      setFiltersArray({
        ...filtersArray,
        programm: [...filtersArray.programm, item],
      });
    }
  };
  const changeYearsFilter = (item) => {
    if (filtersArray.years.includes(item)) {
      setFiltersArray({
        ...filtersArray,
        years: filtersArray.years.filter((point) => point !== item),
      });
    } else {
      setFiltersArray({
        ...filtersArray,
        years: [...filtersArray.years, item],
      });
    }
  };

  useEffect(() => {
    if (filtersArray.length < 1) return;
    dispatch(getFiltersAction(filtersArray));
  }, [filtersArray]);

  return (
    <div className={styles.filters}>
      <div className={styles.terr}>
        <ObjectControlTerritory
          onChangeHandler={changeTerritoryFilter}
          filtersArray={filtersArray}
        />
      </div>
      <div className={styles.prog}>
        <ObjectControlProgramm
          onChangeHandler={changeProgramFilter}
          filtersArray={filtersArray}
        />
      </div>
      <div className={styles.year}>
        <ObjectControlYear
          filter={filter}
          onChangeHandler={changeYearsFilter}
          filtersArray={filtersArray}
        />
      </div>

      <button
        className={styles.button_filters}
        type='button'
        onClick={() => setModalActive(true)}
      >
        <span>Фильтры</span>
      </button>

      {modalActive && (
        <Modal active={modalActive} setActive={setModalActive}>
          <div className={classnames([styles.terr, styles.modal])}>
            <ObjectControlTerritory
              modal='modal'
              onChangeHandler={changeTerritoryFilter}
              filtersArray={filtersArray}
            />
          </div>
          <div className={classnames([styles.prog, styles.modal])}>
            <ObjectControlProgramm
              modal='modal'
              onChangeHandler={changeProgramFilter}
              filtersArray={filtersArray}
            />
          </div>
          <div className={classnames([styles.year, styles.modal])}>
            <ObjectControlYear
              modal='modal'
              onChangeHandler={changeYearsFilter}
              filtersArray={filtersArray}
              filter={filter}
            />
          </div>

          <button
            type='button'
            disabled={filteringCount == 0}
            className={styles.apply}
            onClick={() => setModalActive(false)}
          >
            <span>
              {isFiltered
                ? filteringCount == 0
                  ? 'Объектов нет'
                  : 'Показать ' +
                    filteringCount +
                    ' ' +
                    changeWordEnding(filteringCount, [
                      'объект',
                      'объекта',
                      'объектов',
                    ])
                : 'Выберите фильтры'}
            </span>
          </button>
        </Modal>
      )}
    </div>
  );
};

export default ObjectContentFilters;
