import { combineReducers } from 'redux';
import { newsReducer } from './Reducers/newsReducer';
import { objectsReducer } from './Reducers/objectsReducer';
import { partnersReducer } from './Reducers/partnersReducer';
import { documReducer } from './Reducers/documReducer';
import { purchasesReducer } from './Reducers/purchasesReducer';
import { procurementsReducer } from './Reducers/procurementsReducer';
import { organizationsReducer } from './Reducers/organizationsReducer';
import { workersReducer } from './Reducers/workersReducer';
import { linksReducer } from './Reducers/linksReducer';
import { vacancyReducer } from './Reducers/vacancyReducer';
import { superVisioryReducer } from './Reducers/superVisioryReducer';
import { expertsReducer } from './Reducers/expertsReducer';
import { contestsReducer } from './Reducers/contestsReducer';
import { objectFiltersReducer } from './Reducers/objectFiltersReducer';
import { MPNewsReducer } from './Reducers/MPnewsReducer';
import { MPsReducer } from './Reducers/MPsReducer';
import { MPIdeasReducer } from './Reducers/MPIdeasReducer';

export const rootReducer = combineReducers({
  news: newsReducer,
  MPIdeas: MPIdeasReducer,
  MPs: MPsReducer,
  MPNews: MPNewsReducer,
  partners: partnersReducer,
  objects: objectsReducer,
  documents: documReducer,
  purchasesAggregators: purchasesReducer,
  procurements: procurementsReducer,
  organizations: organizationsReducer,
  workers: workersReducer,
  links: linksReducer,
  vacancies: vacancyReducer,
  supervisiory: superVisioryReducer,
  experts: expertsReducer,
  contests: contestsReducer,
  object_filters: objectFiltersReducer,
});
