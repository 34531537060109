import React, { useRef } from 'react';
import styles from './ObjectsAside.module.scss';

import ObjectAsideItem from './ObjectAsideItem/ObjectAsideItem';
import { useEffect } from 'react';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getNumberFilteringObjectsAction } from '../../../../redux/Reducers/objectsReducer';
import { useParams } from 'react-router-dom';

const ObjectsAside = ({
  data,
  hoverHandler,
  filter,
  activeMarker,
  getFiltereObjects,
}) => {
  const [filteredArray, setFilteredArray] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [scrollbarPosition, setScrollbarPosition] = useState(0);
  const [careteHolder, setCareteHolder] = useState(false);

  const params = useParams();
  const crolledBlock = useRef();

  const scrollHandler = (e) => {
    const elem = e.target;
    const delta = elem.scrollHeight - elem.clientHeight;
    let scrolledNumber = (elem.scrollTop / delta) * 100;
    if (scrolledNumber < 0) {
      scrolledNumber = 0;
    } else if (scrolledNumber > 100) {
      scrolledNumber = 100;
    } else {
      scrolledNumber = scrolledNumber;
    }
    setScrollbarPosition(scrolledNumber);
  };

  const dispatch = useDispatch();

  if (data && data.length > 0) {
    data.sort(function (a, b) {
      var nameA = a.territory.toLowerCase(),
        nameB = b.territory.toLowerCase();
      if (nameA < nameB)
        //сортируем строки по возрастанию
        return -1;
      if (nameA > nameB) return 1;
      return 0; // Никакой сортировки
    });
  }

  document.addEventListener('mouseup', () => {
    if (careteHolder) {
      setCareteHolder(false);
    }
  });
  useEffect(() => {
    dispatch(getNumberFilteringObjectsAction([filteredArray.length, filtered]));
  }, [filtered, filteredArray]);

  useEffect(() => {
    if (!filter) return;
    if (filter.territory === 'Все территории')
      dispatch(getFiltereObjects({ territory: '' }));
    if (
      filter.territory.length < 1 &&
      filter.programm.length < 1 &&
      filter.years.length < 1
    ) {
      setFilteredArray([]);
      setFiltered(false);
      return;
    }

    let result = [];
    if (
      filter.territory.length > 0 ||
      filter.programm.length > 0 ||
      filter.years.length > 0
    ) {
      setFiltered(true);
    }
    if (
      filter.territory.length > 0 &&
      filter.programm.length > 0 &&
      filter.years.length > 0
    ) {
      result = data.filter(
        (item) =>
          filter.territory == item.territory &&
          filter.programm.includes(item.program) &&
          filter.years.includes(item.year.year)
      );
    } else if (filter.territory.length > 0 && filter.programm.length > 0) {
      result = data.filter(
        (item) =>
          filter.territory == item.territory &&
          filter.programm.includes(item.program)
      );
    } else if (filter.programm.length > 0 && filter.years.length > 0) {
      result = data.filter(
        (item) =>
          filter.years.includes(item.year.year) &&
          filter.programm.includes(item.program)
      );
    } else if (filter.territory.length > 0 && filter.years.length > 0) {
      result = data.filter(
        (item) =>
          filter.years.includes(item.year.year) &&
          filter.territory == item.territory
      );
    } else if (filter.programm.length > 0) {
      result = data.filter((item) => filter.programm.includes(item.program));
    } else if (filter.territory.length > 0) {
      result = data.filter((item) => filter.territory == item.territory);
    } else if (filter.years.length > 0) {
      result = data.filter((item) => filter.years.includes(item.year.year));
    }
    result &&
      result.length > 0 &&
      result.sort(function (a, b) {
        var nameA = a.territory.toLowerCase(),
          nameB = b.territory.toLowerCase();
        if (nameA < nameB)
          //сортируем строки по возрастанию
          return -1;
        if (nameA > nameB) return 1;
        return 0; // Никакой сортировки
      });

    setFilteredArray(result);
  }, [filter, data]);

  useEffect(() => {
    if (!filteredArray || params.id) return;
    getFiltereObjects(filteredArray, filtered);
  }, [filteredArray]);

  return (
    <div className={styles.aside} style={isMobile ? { marginTop: 30 } : {}}>
      <ul
        ref={crolledBlock}
        onScroll={scrollHandler}
        style={isMobile ? { maxHeight: 'none' } : {}}
      >
        {!filtered
          ? data.map((item, index) => {
              return (
                <ObjectAsideItem
                  key={index}
                  id={item.id}
                  title={item.name}
                  street={item.address}
                  status={item.status}
                  loc1={item.marker_latitude}
                  loc2={item.marker_longitude}
                  image={item.photos}
                  hoverHandler={hoverHandler}
                  activeMarker={activeMarker}
                  crolledBlock={crolledBlock}
                />
              );
            })
          : filteredArray.map((item, index) => {
              return (
                <ObjectAsideItem
                  key={index}
                  id={item.id}
                  title={item.name}
                  street={item.address}
                  status={item.status}
                  loc1={item.marker_latitude}
                  loc2={item.marker_longitude}
                  image={item.photos}
                  hoverHandler={hoverHandler}
                  activeMarker={activeMarker}
                  crolledBlock={crolledBlock}
                />
              );
            })}
      </ul>
    </div>
  );
};

export default ObjectsAside;
