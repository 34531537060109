import React from "react";

import arrow from "../../../../../images/arrowFullScreenImage.svg";
import closeImg from "../../../../../images/closeFullScreenImg.svg";
import load from "../../../../../images/load.gif";

import styles from "./FullScreenImage.module.scss";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

const FullScreenImage = ({ imageList, close, current, next, prev }) => {
  const [isLoad, setLoad] = useState(true);
  const [lastAction, setLastAction] = useState("next");

  document.addEventListener("keydown", (e) => {
    if (e.keyCode === 37) {
      clickPrev();
    }
    if (e.keyCode === 39) {
      clickNext();
    }
    if (e.keyCode === 27) {
      clickClose();
    }
  });

  const clickNext = () => {
    setLastAction("next");
    setLoad(true);
    next();
  };
  const clickPrev = () => {
    setLastAction("prev");
    setLoad(true);
    prev();
  };
  const clickClose = () => {
    setLastAction("next");
    setLoad(false);
    close();
  };

  return (
    <div className={styles.modalWrapper}>
      {imageList.length > 1 && (
        <img src={arrow} className={styles.prev} onClick={clickPrev} />
      )}
      {isLoad && <img src={load} style={{ width: 40 }} />}

      <div
        style={{
          width: isLoad ? 0 : "auto",
          // maxWidth: "50vw",
          // maxHeight: "50vh",
        }}
      >
        <img
          onLoad={() => setLoad(false)}
          onError={lastAction === "next" ? clickNext : clickPrev}
          // style={isLoad ? { width: "0px" } : { width: "70%" }}
          src={current}
          className={styles.photo}
          alt={"img"}
        />
      </div>
      {imageList.length > 1 && (
        <img src={arrow} className={styles.next} onClick={clickNext} />
      )}

      <img src={closeImg} onClick={clickClose} className={styles.close} />
    </div>
  );
};

export default FullScreenImage;
