import React from 'react';

import styles from '../PurchasesAggregator.module.scss';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as ShowFilterArrow } from '../../../../../../images/show-filter-arrow.svg';
import { useParams } from 'react-router-dom';

const PurchasesFilter = ({ onChange, searchFiled }) => {
  const [activeFilter, setActiveFilter] = useState()
  const [isShowFilter, setShowFilter] = useState(false)

  const { type } = useParams();

  useEffect(() => {
    setActiveFilter();
  }, [type])

  const statusRedux = ['Идёт приём заявок', 'Приём заявок завершён', 'Подведены итоги']

  const listItemClickHandler = (elem) => {
    setActiveFilter(elem)
    onChange(elem)
    setShowFilter(false)
  }

  useEffect(() => {
    listItemClickHandler()
  }, [searchFiled])

  return (
    <div className={styles.registryFilterWrap}>
        <p onClick={() => setShowFilter(!isShowFilter)}>{activeFilter ? activeFilter : 'Статус закупки'}</p>
        <ShowFilterArrow style={isShowFilter ? {transform: 'rotate(180deg)'} : {}} onClick={() => setShowFilter(!isShowFilter)} />
        {isShowFilter && (
            <ul className={styles.filterList}>
                    <li onClick={() => listItemClickHandler()}><div style={!activeFilter ? {backgroundColor: '#FF2E17'} : {}}></div> Все закупки</li>
                {statusRedux.map((item, index) => (
                    <li key={index} onClick={() => listItemClickHandler(item)}><div style={activeFilter && activeFilter === item ? {backgroundColor: '#FF2E17'} : {}}></div> {item}</li>
                ))}
            </ul>
        )}
    </div>
  )
}

export default PurchasesFilter;