import {
  getObjectsAction,
  setObjectsTerritoriesAction,
  setObjectsYearsAction,
} from "../redux/Reducers/objectsReducer";
import { BASE_URL } from "./BASE_URL";

export const getObjects = () => {
  return async (dispatch) => {
    const response = await fetch(BASE_URL + "objects/?limit=550");
    const data = await response.json();

    if (response.status === 200) {
      const years = [];
      data.results.map((item) => {
        if (item.year && !years.includes(item.year.year)) {
          years.push(item.year.year);
        }
      });
      const territory = [];
      data.results.map((item) => {
        if (item.territory) {
          territory.push(item.territory);
        }
      });

      dispatch(setObjectsTerritoriesAction(territory));
      dispatch(setObjectsYearsAction(years));
      dispatch(getObjectsAction(data.results));
    }
  };
};
