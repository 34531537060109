import React from "react";
import styles from "./RegistrSecondaryInfo.module.scss";
import { isMobile } from 'react-device-detect';

const RegistrSecondaryInfo = ({
  full_name,
  short_name,
  link,
  description,
  documents,
  improvement_objects,
}) => {
  const getExtention = (string) => {
    const indexDot = string.lastIndexOf(".");
    return string.slice(indexDot);
  };
  const linkFormatter = (link) => {
    const deleteCount = link.includes('http:') ? 7 : 8;

    return link.slice(deleteCount)
  }
  const isInfo = description || improvement_objects.length > 0 || documents.length > 0
  return (
    (description || improvement_objects.length > 0 || documents.length > 0 || link) && (
      <div className={styles.item}>
      <div className={styles.company} style={!isInfo ? {marginBottom: 0} : {}}>
        <h3 style={!isInfo ? {marginBottom: 0} : {}}>{full_name ? full_name : short_name}</h3>
        {link && <a href={link} style={!isInfo ? {marginBottom: 0} : {}}>{linkFormatter(link)}</a>}
        {description && <p>{description}</p>}
      </div>

      {improvement_objects.length > 0 && (
        <div className={styles.objects}>
        <h4>Объекты</h4>
        {improvement_objects.map((item, index) => <p key={index}><a href={item.link}>{item.name}</a></p>) }
      </div>
      )}

      

      {documents && documents.length > 0 && (
        <div className={styles.docum}>
          <h4>Документы</h4>
          {documents.map((item, index) => (
            <p key={index}>
              <a href={item.file}>
                <span>{item.name}</span>
                <span>{getExtention(item.file)}</span>
              </a>
            </p>
          ))}
        </div>
      )}
    </div>
    )
    
  );
};

export default RegistrSecondaryInfo;
