import React from "react";
import styles from "./RegistrMainInfo.module.scss";
import ButtonClose from "../../../../../../../universal_components/ButtonClose/ButtonClose";
import { isMobile } from "react-device-detect";

const RegistrMainInfo = ({
  short_name,
  contacts,
  onChange,
  isShow,
  description,
  documents,
  improvement_objects,
  accreditation_direction,
  link,
}) => {
  const windowWidth = window.innerWidth;

  return (
    <button className={`${styles.mainInfo} `} onClick={onChange}>
      {/* ${styles.pressed} */}
      {!short_name ? (
        <div className={styles.empty}>
          <p>Нет соответствующих организаций</p>
        </div>
      ) : (
        <>
          {" "}
          <p className={styles.organization}>
            <span>{short_name}</span>
          </p>
          {window.innerWidth > 768 &&
            (accreditation_direction.length > 0 ? (
              <p className={styles.direction}>
                {accreditation_direction.map((item, index) => (
                  <>
                    <span key={index}>{item.title}</span> <br />
                  </>
                ))}
              </p>
            ) : (
              <p className={styles.direction}>Не указано</p>
            ))}
          {/* {window.innerWidth > 768 &&  (
            <p className={styles.direction}>
              <span>{accreditation_direction ? accreditation_direction.title : 'Не указано'} </span>
            </p>
          )} */}
          {window.innerWidth < 768 && accreditation_direction.length > 0 && (
            <p className={styles.direction}>
              {accreditation_direction.map((item, index) => (
                <>
                  <span key={index}>{item.title}</span> <br />
                </>
              ))}
            </p>
          )}
          <div className={styles.contact}>
            {contacts &&
              contacts.map((item, index) => (
                <p key={index} className={styles.name}>
                  {item.text}
                </p>
              ))}
          </div>
          {isMobile &&
            windowWidth < 769 &&
            (description ||
              documents.length > 0 ||
              improvement_objects.length > 0 ||
              link) && <ButtonClose open={isShow} onChange={onChange} />}
        </>
      )}
    </button>
  );
};

export default RegistrMainInfo;
