import classnames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import RegistrItem from "./RegistrItem/RegistrItem";
import styles from "./RegistrTable.module.scss";
import { useManufactureFilter } from "../../../../../../store/store";

const RegistrTable = ({ data, searchFiled, activeFilter }) => {
  const [filteredArray, setFilteredArray] = useState([])

  useEffect(() => {
    if (!searchFiled || searchFiled === '') {
      setFilteredArray(data)
    } else {
      const filtered = data.filter(item => item.short_name.toLowerCase().includes(searchFiled.toLowerCase()))
      setFilteredArray(filtered)
    }
  }, [searchFiled, data])

  useEffect(() => {
    if (!activeFilter || activeFilter === null) {
      setFilteredArray(data)
    } else {
      let filtered = []
      data.forEach(item => {
        if (item.accreditation_direction.length > 0) {
          item.accreditation_direction.forEach(element => {
            if (element.title === activeFilter.title) filtered.push(item)
          })
        }
      })
      setFilteredArray(filtered)
    }
  }, [activeFilter, data])

  const manufactures = useManufactureFilter(state => state.manufactures)

  useEffect(() => {
    setFilteredArray(data?.filter(item => item.category == 'local' && manufactures.includes(item.manufacture)))
  }, [manufactures])
  


  return (
    <ul className={styles.table}>
      <li className={classnames([styles.item, styles.head])}>
        <div className={styles.inner}>
          <p>Организация</p>
          <p>Направления</p>
          <p>Контакты</p>
        </div>
      </li>

      {filteredArray && filteredArray.length > 0 ? filteredArray.map((item, index) => <RegistrItem key={index} data={item} />) : <RegistrItem data={false} />}
    </ul>
  );
};

export default RegistrTable;
