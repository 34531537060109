const initialState = {
    experts: [],
    text: '',
}

const GET_EXPERTS = 'GET_EXPERTS';
const GET_EXPERTS_TEXT = 'GET_EXPERTS_TEXT';

export const expertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXPERTS:
            return {...state,
                experts: action.payload,
            }
        case GET_EXPERTS_TEXT:
            return {...state,
                text: action.payload,
            }    
        default:
            return state;
    }
}

export const getExpertsAction = (payload) => ({type: GET_EXPERTS, payload})
export const getExpertsTextAction = (payload) => ({type: GET_EXPERTS_TEXT, payload})