const initialState = {
    objects: [],
    filteringCount: 0,
    isFiltered: false,
    territory: [],
    years: [],
}

const GET_OBJECTS = 'GET_OBJECTS';
const SET_YEARS = 'SET_YEARS';
const SET_TERRITORY = 'SET_TERRITORY'
const GET_NUMBER_FILTERING_OBJECTS = 'GET_NUMBER_FILTERING_OBJECTS';

export const objectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_OBJECTS:
            return {...state,
                objects: action.payload,
            }
        case SET_YEARS:
            return {...state,
                years: action.payload,    
            }
        case SET_TERRITORY:
            return {...state,
                territory: action.payload,
            }
        case GET_NUMBER_FILTERING_OBJECTS:
            return {...state,
                filteringCount: action.payload[0],
                isFiltered: action.payload[1],
            }
        default:
            return state;
    }
}

export const getObjectsAction = (payload) => ({type: GET_OBJECTS, payload})
export const setObjectsYearsAction = (payload) => ({type: SET_YEARS, payload})
export const setObjectsTerritoriesAction = (payload) => ({type: SET_TERRITORY, payload})
export const getNumberFilteringObjectsAction = (payload) => ({type: GET_NUMBER_FILTERING_OBJECTS, payload})