import React from "react";
import styles from "./DescriptionItem.module.scss";
import classnames from "classnames";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveVacancyAction } from '../../../../../../redux/Reducers/vacancyReducer';
import { isMobile } from 'react-device-detect';
import { useEffect } from "react";

const DescriptionItem = ({ name, requirements, id }) => {
  const dispatch = useDispatch();

  const active = useSelector(state => state)

  const getFirstCondition = (requirementsList) => {
    const result = requirementsList.split("\r\n")[0];
    switch (result[result.length - 1]) {
      case ';' || '.' || ',':
        return result.slice(0, -1);
      default:
        return result
    }
  };

  return (
    <div className={styles.desc} onClick={() => dispatch(setActiveVacancyAction(id))}>
      <a className={styles.item}>
        <h3>{name}</h3>
        {!isMobile && <p>{getFirstCondition(requirements)}</p>}
      </a>
    </div>
  );
};

export default DescriptionItem;
